export const urljoin = (...args) => {
  let url = args[0];

  for (let i = 1; i < args.length; i++) {
    let joinStr = url.endsWith("/") || args[i].startsWith("/") ? "" : "/";

    url = url + joinStr + urljoin(args[i]);
  }

  return url;
};

export const toDateString = (date) => {
  const dateFormatter = Intl.DateTimeFormat("sv-SE");
  return date instanceof Date ? dateFormatter.format(date) : date;
};

export const fileDownload = (data, filename) => {
  const blob = new Blob([data], { type: "mime" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const noop = () => {};

export const downloadResponseFile = (res) => {
  const contentDisposition = res.headers["content-disposition"];
  let filename = "";
  if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }
  filename = decodeURIComponent(filename);
  fileDownload(res.data, filename);
};

export const strToDate = (str) => {
  let date = Date.parse(str);

  return date ? new Date(date) : null;
};

export const toReadable = (string) => {
  let word = string.replace(/[^A-Za-z0-1\s\-]+/, " ");
  word = word.toLowerCase();

  let firstLetter = word.charAt(0);

  let firstLetterCap = firstLetter.toUpperCase();

  let remainingLetters = word.slice(1);

  return firstLetterCap + remainingLetters;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
