import PropTypes from "prop-types";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { ProjectFileType, ProjectStatus } from "share/constants";
import { toReadable } from "share/utils";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as api from "api";

let detailBoxStyle = {
  height: 40,
  display: "flex",
  alignItems: "center",
};

const ProjectStatusField = (props) => {
  const renderStatus = () => (
    <Typography variant="body1">
      {toReadable(props.taskStatus.status)}
    </Typography>
  );

  const renderCompleteStatus = () => (
    <>
      <Tooltip title={`Last Update: ${props.taskStatus.update_date}`}>
        <Typography variant="body1">
          {toReadable(props.taskStatus.status)}
        </Typography>
      </Tooltip>

      <Tooltip title={props.title} sx={{ marginLeft: "5px" }}>
        <IconButton onClick={props.handleDownloadReport}>
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  if (props.taskStatus?.status.toUpperCase() === ProjectStatus.COMPLETE) {
    return renderCompleteStatus();
  }
  return renderStatus();
};

const ProjectDetailPanel = (props) => {
  const handleDownloadIntermediateFile = async () => {
    await api.downloadProjectFileApi(
      props.projectId,
      ProjectFileType.GENERATED_WP_FILE
    );
  };

  const handleDownloadReport = async () => {
    await api.downloadProjectFileApi(
      props.projectId,
      ProjectFileType.GENERATED_REPORT_FILE
    );
  };

  return (
    <Box
      sx={{
        margin: "auto",
        width: "80%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={4} sm={6}>
          <Typography variant="body1" gutterBottom sx={{ fontWeight: 700 }}>
            Project Owner:
          </Typography>
          <Box sx={detailBoxStyle}>
            <Typography variant="body1">{props.project_owner}</Typography>
          </Box>
        </Grid>
        <Grid item md={4} sm={6}>
          <Typography variant="body1" gutterBottom sx={{ fontWeight: 700 }}>
            Template:
          </Typography>
          <Box sx={detailBoxStyle}>
            <Typography variant="body1">{props.template}</Typography>
          </Box>
        </Grid>
        <Grid item md={4} sm={6}>
          <Typography variant="body1" gutterBottom sx={{ fontWeight: 700 }}>
            Create Date:
          </Typography>
          <Box sx={detailBoxStyle}>
            <Typography variant="body1">{props.createDate}</Typography>
          </Box>
        </Grid>
        <Grid item md={4} sm={6}>
          <Typography variant="body1" gutterBottom sx={{ fontWeight: 700 }}>
            Extract Tables Status:
          </Typography>
          <Box sx={detailBoxStyle}>
            <ProjectStatusField
              title={"Download intermediate file"}
              handleDownloadReport={handleDownloadIntermediateFile}
              taskStatus={props.extractTableStatus}
            />
          </Box>
        </Grid>
        <Grid item md={4} sm={6}>
          <Typography variant="body1" gutterBottom sx={{ fontWeight: 700 }}>
            Generate Document Status:
          </Typography>
          <Box sx={detailBoxStyle}>
            <ProjectStatusField
              title={"Download generated report"}
              handleDownloadReport={handleDownloadReport}
              taskStatus={props.generateDocumentStatus}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

ProjectDetailPanel.propTypes = {
  projectId: PropTypes.number.isRequired,
  project_owner: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  createDate: PropTypes.string.isRequired,
  extractTableStatus: PropTypes.object,
  generateDocumentStatus: PropTypes.object,
  generatedWpFile: PropTypes.object,
  generatedDocument: PropTypes.object,
};

export default ProjectDetailPanel;
