import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import UploadArea from "../UI/UploadArea/UploadArea";

const UploadTemplateModal = (props) => {
  const { open, onClose, onSubmit, isCreate, template } = props;

  const [form, setForm] = useState({
    companyTemplateId: null,
    templateName: "",
    masterFiles: [],
    specialConfigFiles: [],
    enTemplateFiles: [],
    tableTemplateFiles: [],
    qReverseTemplateFiles: [],
    qReverseTextFiles: [],
    customizedWPTemplateFiles: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (prop) => (files) => {
    setForm((prevFormData) => {
      prevFormData[prop] = files;
      return { ...prevFormData };
    });
  };

  const handleTemplateNameChange = (e) => {
    setForm((prevFormData) => {
      prevFormData.templateName = e.target.value;
      return { ...prevFormData };
    });
  };

  const validate = () => {
    return (
      !isLoading &&
      form.templateName !== "" &&
      form.masterFiles.length > 0 &&
      form.specialConfigFiles.length >0 &&
      form.enTemplateFiles.length > 0 &&
      form.tableTemplateFiles.length > 0 &&
      form.qReverseTemplateFiles.length > 0 &&
      form.qReverseTextFiles.length > 0 &&
      form.customizedWPTemplateFiles.length > 0
    );
  };

  const handleSubmit = async () => {
    let formData = {
      templateName: form.templateName,
      masterFile: form.masterFiles[0].file,
      specialConfigFile: form.specialConfigFiles[0].file,
      enTemplateFile: form.enTemplateFiles[0].file,
      tableTemplateFile: form.tableTemplateFiles[0].file,
      qReverseTemplateFile: form.qReverseTemplateFiles[0].file,
      qReverseTextFile: form.qReverseTextFiles[0].file,
      customizedWPTemplateFile: form.customizedWPTemplateFiles[0].file,
      companyTemplateId: form.companyTemplateId,
    };

    setIsLoading(true);
    try {
      await onSubmit(formData, isCreate);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (template) {
      setForm((prevFormData) => {
        return {
          ...prevFormData,
          templateName: template.template,
          companyTemplateId: template.id,
        };
      });
    }
  }, [template]);

  useEffect(() => {
    if (!open) {
      setForm({
          companyTemplateId: null,
          templateName: "",
          masterFiles: [],
          specialConfigFiles: [],
          enTemplateFiles: [],
          tableTemplateFiles: [],
          qReverseTemplateFiles: [],
          qReverseTextFiles: [],
          customizedWPTemplateFiles: [],
        }
      );
    }
    setIsLoading(false);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Template Details</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1, width: "100%" }}>
              <TextField
                label="Template Name"
                id="template-name"
                value={form.templateName}
                placeholder="Enter name of Company Template"
                onChange={handleTemplateNameChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <UploadArea
              files={form.masterFiles}
              onUpdateFiles={handleFileChange("masterFiles")}
              allowMultiple={false}
              maxFiles={1}
              name={"Master File"}
              acceptedFileTypes={[".xlsx"]}
            />
          </Grid>
          <Grid item xs={6}>
            <UploadArea
              files={form.specialConfigFiles}
              onUpdateFiles={handleFileChange("specialConfigFiles")}
              allowMultiple={false}
              maxFiles={1}
              name={"Special Config File"}
              acceptedFileTypes={[".txt"]}
            />
          </Grid>
          <Grid item xs={6}>
            <UploadArea
              files={form.enTemplateFiles}
              onUpdateFiles={handleFileChange("enTemplateFiles")}
              allowMultiple={false}
              maxFiles={1}
              name={"En Template File"}
              acceptedFileTypes={[".docx"]}
            />
          </Grid>
          <Grid item xs={6}>
            <UploadArea
              files={form.tableTemplateFiles}
              onUpdateFiles={handleFileChange("tableTemplateFiles")}
              allowMultiple={false}
              maxFiles={1}
              name={"Table Template File"}
              acceptedFileTypes={[".xlsx"]}
            />
          </Grid>
          <Grid item xs={6}>
            <UploadArea
              files={form.qReverseTemplateFiles}
              onUpdateFiles={handleFileChange("qReverseTemplateFiles")}
              allowMultiple={false}
              maxFiles={1}
              name={"Q Reverse Template File"}
              acceptedFileTypes={[".xlsx"]}
            />
          </Grid>
          <Grid item xs={6}>
            <UploadArea
              files={form.qReverseTextFiles}
              onUpdateFiles={handleFileChange("qReverseTextFiles")}
              allowMultiple={false}
              maxFiles={1}
              name={"Q Reverse Text File"}
              acceptedFileTypes={[".txt"]}
            />
          </Grid>
          <Grid item xs={6}>
            <UploadArea
              files={form.customizedWPTemplateFiles}
              onUpdateFiles={handleFileChange("customizedWPTemplateFiles")}
              allowMultiple={false}
              maxFiles={1}
              name={"Customized WP Template File"}
              acceptedFileTypes={[".xlsx"]}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={handleSubmit}
          disabled={!validate()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UploadTemplateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  template: PropTypes.object,
  isCreate: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default UploadTemplateModal;