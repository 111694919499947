import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import ProjectView from "./container/Home/ProjectView";
import { Container } from "@mui/material";
import { Auth0Provider, useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect } from "react";
import { axiosConfig } from "./share/axios";
import * as actionTypes from "./store/actions/actionTypes";
import * as actions from "store/actions";
import UserInfo from "./component/User/UserInfo";
import Logout from "./container/Logout/Logout";
import LoadingMask from "./component/UI/LoadingMask";
import { Navigate } from "react-router-dom/dist";
import AdminPage from "container/Admin/AdminPage";
import GuardedRoute from "component/UI/GuardedRoute";
import ProjectForm from "container/Home/ProjectForm";

const Auth = (props) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  return (
    <Auth0Provider
      useFormData
      domain={process.env.REACT_APP_DOMAIN}
      clientId={process.env.REACT_APP_CLIENT_ID}
      redirectUri={window.location.origin + process.env.PUBLIC_URL + "/authorized"}
      onRedirectCallback={onRedirectCallback}
    >
      {props.children}
    </Auth0Provider>
  );
};

const AppInner = () => {
  const dispatch = useDispatch();

  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();

  const isUserAuthenticated = useSelector(state => state.user.isAuthenticated);
  const isAuthenticationFinished = useSelector(state => state.user.authenticationFinished);

  const handleLogout = () => {
    logout({ returnTo: process.env.REACT_APP_DOMAIN });
  };

  useLayoutEffect(() => {
    if (isAuthenticated) {
      dispatch(actions.getUserDetail());

      axiosConfig.getToken = getAccessTokenSilently;
      dispatch({
        type: actionTypes.LOGIN_SUCCESS,
        ...user
      });
    }
  }, [isAuthenticated, user, dispatch, getAccessTokenSilently]);

  const {
    isSuccess,
    isAdmin
  } = useSelector((state) => {
    return {
      isSuccess: state.auth.isSuccess,
      isAdmin: state.user.isAdmin
    };
  });

  if (!isAuthenticated || !isSuccess || !isAuthenticationFinished) {
    return (
      <LoadingMask show={true} />
    );
  } else if (isAuthenticationFinished && isUserAuthenticated) {
    return (
      <div className="App">
        <UserInfo onLogout={handleLogout} />

        <Container maxWidth="lg" sx={{ marginTop: 5, marginBottom: 5 }}>
          <Routes>
            <Route exact path="/" element={<ProjectView />} />
            <Route exact path="/project/:projectId" element={<ProjectForm />} />
            <Route
              path="/admin"
              element={
                <GuardedRoute hasAccess={isAdmin}>
                  <AdminPage />
                </GuardedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Container>
      </div>

    );
  } else {
    return (
      <Logout onLogout={handleLogout} countDown={3} />
    );
  }

};

const AppComponent = withAuthenticationRequired(AppInner);

const App = (props) =>  {
  return (
    <Auth>
      <AppComponent />
    </Auth>
  );
}

export default App;
