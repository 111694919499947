import auditorReducer from "./project";
import authReducer from "./auth";
import userReducer from "./user";
import { combineReducers } from "redux";

export default combineReducers({
  auditor: auditorReducer,
  auth: authReducer,
  user: userReducer
});
