import { downloadResponseFile, toDateString, urljoin } from "../share/utils";
import axios from "share/axios";
import { toast } from "react-toastify";

export const getProjectConfigApi = (projectId) => {
  let id = projectId || "";
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `project-config/${id}`
  );

  return axios.get(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const createProjectApi = (projectName, templateId, workingPaperType) => {
  let url = urljoin(process.env.REACT_APP_AUDITOR_SERVICE, "/project");

  let formData = new FormData();
  formData.append("project_name", projectName);
  formData.append("template_id", templateId);
  formData.append("wp_template_type", workingPaperType);

  return axios.post(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const updateProjectApi = (projectId, projectName) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/project/${projectId}`
  );

  let formData = new FormData();
  formData.append("project_name", projectName);

  return axios.put(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const updateProjectDetailApi = (projectId, form) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/update-project-detail/${projectId}`
  );

  let formData = new FormData();
  formData.append("company_name", form["company_name"]);
  formData.append("company_name_zh", form["company_name_zh"]);
  formData.append("curr_from_date", toDateString(form["curr_from_date"]));
  formData.append("curr_to_date", toDateString(form["curr_to_date"]));
  formData.append("consolidate_report", form["consolidate_report"]);
  formData.append("currency_id", form["currency_id"]);
  formData.append("auditor", form["auditor"]);
  formData.append("auditor_zh", form["auditor_zh"]);
  formData.append("include_zh", form["include_zh"]);
  formData.append("is_first_audit", form["is_first_audit"]);
  formData.append("prev_from_date", toDateString(form["prev_from_date"]));
  formData.append("prev_to_date", toDateString(form["prev_to_date"]));
  formData.append("prev_auditor", form["prev_auditor"]);
  formData.append("prev_auditor_zh", form["prev_auditor_zh"]);

  return axios.post(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const uploadPrevDocApi = (projectId, prevDocFile, wpFile) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/upload-document/${projectId}`
  );

  let formData = new FormData();
  formData.append("prev_doc_file", prevDocFile);
  formData.append("wp_file", wpFile);

  return axios.post(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const selectNotesApi = (projectId, noteIds) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/select-notes/${projectId}`
  );

  let formData = new FormData();
  noteIds.forEach((ele) => formData.append("note_ids", ele));

  return axios.post(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const extractExcelApi = (projectId, regenerate = false) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/extract-excel-table/${projectId}`
  );

  let form = new FormData();
  form.append("regenerate", regenerate);

  return axios.post(url, form).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const checkIntermediateFileApi = (projectId, excelFile) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/check-intermediate-file/${projectId}`
  );

  let formData = new FormData();
  formData.append("excel_file", excelFile);

  return axios
    .post(url, formData, {
      responseType: "blob",
    })
    .then((res) => {
      downloadResponseFile(res);
    })
    .catch(async (error) => {
      let message;
      if (error.code === "ECONNABORTED") {
        message = "Timeout";
      } else if (
        error.response !== undefined &&
        error.response.data !== undefined
      ) {
        message = await error.response.data.text();

        try {
          let jsonData = JSON.parse(message);
          message = jsonData.message;
        } catch (e) { }
      }
      toast.error(message, {
        autoClose: 5000,
      });

      throw error;
    });
};

export const checkIntermediateTableTabsApi = (projectId, excelFile) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/generate-document/${projectId}/check_file_tabs`
  );

  let formData = new FormData();
  formData.append("excel_file", excelFile);

  return axios.post(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const generateDocumentApi = (projectId, selectedTab) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/generate-document/${projectId}`
  );

  let formData = new FormData();
  formData.append("selected_tab", selectedTab);

  return axios.post(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const extractNotesApi = (projectId) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/get-notes/${projectId}`
  );

  return axios.get(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const getProjectDetailApi = (projectId) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/project/${projectId}`
  );

  return axios.get(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const deleteProjectApi = (projectId) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/project/${projectId}`
  );

  return axios.delete(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const getProjectsApi = (pagination, columnFilters, sorting) => {
  const data = {
    start: `${pagination.pageIndex * pagination.pageSize}`,
    size: `${pagination.pageSize}`,
    filters: JSON.stringify(columnFilters ?? []),
    sorting: JSON.stringify(sorting ?? []),
  };

  const searchParams = new URLSearchParams(data);

  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/projects?${searchParams.toString()}`
  );

  return axios.get(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const downloadProjectFileApi = (projectId, fileType) => {
  let data = { file_type: fileType };
  const searchParams = new URLSearchParams(data);

  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/project_file/${projectId}?${searchParams.toString()}`
  );

  return axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      downloadResponseFile(res);
    })
    .catch(async (error) => {
      let message;
      if (error.code === "ECONNABORTED") {
        message = "Timeout";
      } else if (
        error.response !== undefined &&
        error.response.data !== undefined
      ) {
        message = await error.response.data.text();

        try {
          let jsonData = JSON.parse(message);
          message = jsonData.message;
        } catch (e) { }
      }
      toast.error(message, {
        autoClose: 5000,
      });

      throw error;
    });
};

export const getNotesApi = (projectId) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `get-notes/${projectId}`
  );

  return axios.get(url).catch((error) => {
    toast.error(error.response.data, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const getProjectTemplatesApi = () => {
  let url = urljoin(process.env.REACT_APP_AUDITOR_SERVICE, `project_templates`);

  return axios.get(url).catch((error) => {
    toast.error(error.response.data, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const downloadCustomizedWPApi = (companyTemplateId) => {
  let url = urljoin(process.env.REACT_APP_AUDITOR_SERVICE, `template_file/${companyTemplateId}`);

  return axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      downloadResponseFile(res);
    })
    .catch(async (error) => {
      let message;
      if (error.code === "ECONNABORTED") {
        message = "Timeout";
      } else if (
        error.response !== undefined &&
        error.response.data !== undefined
      ) {
        message = await error.response.data.text();

        try {
          let jsonData = JSON.parse(message);
          message = jsonData.message;
        } catch (e) { }
      }
      toast.error(message, {
        autoClose: 5000,
      });

      throw error;
    });
};

