import * as actionTypes from "./actionTypes";
import "react-toastify/dist/ReactToastify.css";
import * as api from "api";


const auditorGetProjectConfigStart = () => {
  return {
    type: actionTypes.PROJECT_GET_PROJECT_CONFIG_START
  };
};

const auditorGetProjectConfigSuccess = (data) => {
  return {
    type: actionTypes.PROJECT_GET_PROJECT_CONFIG_SUCCESS,
    notes: data.notes,
    currencies: data.currencies,
    cpa_options: data.cpa_options
  };
};

const auditorGetProjectConfigFail = (message) => {
  return {
    type: actionTypes.PROJECT_GET_PROJECT_CONFIG_FAIL,
    errorMessage: message
  };
};

export const auditorGetProjectConfig = (projectId) => async (dispatch) => {
  dispatch(auditorGetProjectConfigStart());

  try {
    let response = await api.getProjectConfigApi(projectId);
    dispatch(auditorGetProjectConfigSuccess(response.data));
  } catch (e) {
    let message = e.response.data.message;
    dispatch(auditorGetProjectConfigFail(message));
  }
};