import * as actionTypes from "store/actions/actionTypes";

const initialState = {
  authenticationFinished: false,
  isAuthenticated: false,
  name: "",
  username: "",
  email: "",
  phone: "",
  isAdmin: false,
  company: ""
};

const getUserDetailSuccess = (state, action) => {
  return {
    ...state,
    authenticationFinished: true,
    isAuthenticated: true,
    name: action.name,
    username: action.username,
    email: action.email,
    phone: action.phone,
    isAdmin: action.isAdmin,
    company: action.company
  };
};

const userAuthenticationFail = (state, action) => {
  return {
    ...state,
    authenticationFinished: true,
    isAuthenticated: false
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_DETAIL_SUCCESS:
      return getUserDetailSuccess(state, action);
    case actionTypes.USER_AUTHENTICATION_FAIL:
      return userAuthenticationFail(state, action);
    default:
      return state;
  }
};

export default reducer;
