import { Box, Card, Container, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import TabPanel from "component/UI/TabPanel";
import CompanyView from "component/Admin/CompanyView";
import UserUsage from "component/Admin/UserUsage";
import TemplateTable from "component/Admin/TemplateTable";

const AdminPage = (props) =>  {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Card sx={{borderRadius: "10px"}} elevation={3}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Usage" />
            <Tab label="Company Setting" />
            <Tab label="Template Setting" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <UserUsage />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CompanyView />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TemplateTable />
        </TabPanel>
      </Card>
    </Container>

  );
}

export default AdminPage;