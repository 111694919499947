import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

const configureStore = () =>  {
  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          name: "Deeptranslate",
          actionsBlacklist: ["REDUX_STORAGE_SAVE"],
        })
      : compose;

  const store = createStore(
    rootReducer,
    /* preloadedState, */ composeEnhancers(applyMiddleware(thunk))
  );

  return store;
}

export default configureStore;
