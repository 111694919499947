import { Box } from "@mui/material";
import SubmitProjectStepper from "component/Project/FormStepper/SubmitProjectStepper";
import { useParams } from "react-router-dom";

const ProjectForm = (props) => {
  let { projectId } = useParams();

  const id = Number(projectId) || "";

  return (
    <Box sx={{ marginTop: "10px", marginBottom: "10p[x" }}>
      <SubmitProjectStepper projectId={id} />
    </Box>
  );
};

export default ProjectForm;