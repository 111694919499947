import { FilePond, registerPlugin } from "react-filepond";

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

import "./UploadArea.css";
import { formatBytes } from "../../../share/utils";

// file extensions to mime types
var mime = require("mime");

// Register the plugin
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

const getAcceptFormatText = (accept) => {
  const acceptList = accept
    .toString()
    .split(",")
    .map((type) => type.trim());
  if (acceptList.length > 1) {
    return (
      acceptList.slice(0, -1).join(", ") + " or " + acceptList.slice(-1)[0]
    );
  } else {
    return acceptList[0];
  }
};

const UploadArea = (props) => {
  const {
    files,
    onUpdateFiles,
    allowMultiple,
    maxFiles,
    name,
    subTitle,
    acceptedFileTypes,
    fileSize = null,
  } = props;

  let fileTypes = acceptedFileTypes.map((ext) => mime.getType(ext));
  fileTypes = fileTypes.filter((type) => type !== false);

  let warningMessages = `Expect file type: ${acceptedFileTypes.join(", ")}`;

  let label = `<div class="filepond-label">${name}</div>`;

  if (subTitle) {
    label += `<div class="filepond-label-second"> ${subTitle} </div>`;
  }

  label += `<div class="filepond-extension-label"> We accept file in ${getAcceptFormatText(
    acceptedFileTypes
  )} format </div>`;

  if (fileSize) {
    label += `<div class="filepond-extension-label">**Maximum file size: ${formatBytes(
      fileSize,
      0
    )}</div>`;
  }

  return (
    <Box className={"filepond--container"}>
      <FilePond
        element={Box}
        files={files}
        onupdatefiles={onUpdateFiles}
        allowMultiple={allowMultiple}
        maxFiles={maxFiles}
        maxFileSize={fileSize}
        name={name}
        acceptedFileTypes={fileTypes}
        checkValidity={true}
        dropValidation={true}
        labelIdle={label}
        fileValidateTypeLabelExpectedTypes={warningMessages}
        credits={null}
      />
    </Box>
  );
};

UploadArea.prototype = {
  files: PropTypes.array.isRequired,
  onUpdateFiles: PropTypes.func.isRequired,
  allowMultiple: PropTypes.bool.isRequired,
  maxFiles: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  acceptedFileTypes: PropTypes.array.isRequired,
  fileSize: PropTypes.number,
};

export default UploadArea;
