// auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS";
export const USER_AUTHENTICATION_FAIL = "USER_AUTHENTICATION_FAIL";

// auditor tool actions
export const PROJECT_GET_PROJECT_CONFIG_START = "PROJECT_GET_PROJECT_CONFIG_START";
export const PROJECT_GET_PROJECT_CONFIG_SUCCESS = "PROJECT_GET_PROJECT_CONFIG_SUCCESS";
export const PROJECT_GET_PROJECT_CONFIG_FAIL = "PROJECT_GET_PROJECT_CONFIG_FAIL";

// Admin action
export const ADMIN_GET_USAGE_STATISTICS_START = "ADMIN_GET_USAGE_STATISTICS_START";
export const ADMIN_GET_USAGE_STATISTICS_SUCCESS = "ADMIN_GET_USAGE_STATISTICS_SUCCESS";
export const ADMIN_GET_USAGE_STATISTICS_FAIL = "ADMIN_GET_USAGE_STATISTICS_FAIL";

export const ADMIN_GET_USER_DETAILS_START = "ADMIN_GET_USER_DETAILS_START";
export const ADMIN_GET_USER_DETAILS_SUCCESS = "ADMIN_GET_USER_DETAILS_SUCCESS";
export const ADMIN_GET_USER_DETAILS_FAIL = "ADMIN_GET_USER_DETAILS_FAIL";

export const ADMIN_GET_COMPANY_TEMPLATES_START = "ADMIN_GET_COMPANY_TEMPLATES_START";
export const ADMIN_GET_COMPANY_TEMPLATES_SUCCESS = "ADMIN_GET_COMPANY_TEMPLATES_SUCCESS";
export const ADMIN_GET_COMPANY_TEMPLATES_FAIL = "ADMIN_GET_COMPANY_TEMPLATES_FAIL";