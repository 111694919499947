import { useState } from "react";
import * as api from "api";
import { Box, IconButton, Tooltip } from "@mui/material";

import MaterialReactTable from "material-react-table";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadTemplateModal from "./UploadTemplateModal";
import ConfirmDialog from "component/UI/ConfirmDialog";
import TableCellEditField from "component/UI/TableCellEditField";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

const TemplateTable = (props) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [editTemplateModalOpen, setEditTemplateModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);
  const [deleteTemplateDialogOpen, setDeleteTemplateDialogOpen] =
    useState(false);

  const columns = [
    {
      accessorKey: "template",
      header: "Template",
      enableResizing: true,
      size: 500,
      Cell: ({ row, cell }) => (
        <TableCellEditField
          onSubmit={(projectName) =>
            handleUpdateTemplateName(row.original["id"], projectName)
          }
          value={cell.getValue()}
        />
      ),
    },
  ];

  const openEditTemplateModal = (template, isCreate) => {
    setSelectedTemplate(template);
    setEditTemplateModalOpen(true);
    setIsCreateTemplate(isCreate);
  };

  const closeEditTemplateModal = () => {
    setSelectedTemplate(null);
    setEditTemplateModalOpen(false);
  };

  const openConfirmDeleteTemplateDialog = (template) => {
    setSelectedTemplate(template);
    setDeleteTemplateDialogOpen(true);
  };

  const closeConfirmDeleteTemplateDialog = () => {
    setSelectedTemplate(null);
    setDeleteTemplateDialogOpen(false);
  };

  const { data, isError, isFetching, isLoading, refetch } = useQuery(
    [
      "company_template",
      columnFilters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    async () => {
      const res = await api.adminGetCompanyTemplatesApi(
        pagination,
        columnFilters,
        sorting
      );
      return res.data;
    },
    { keepPreviousData: true }
  );

  const handleSubmitTemplate = async (form, isCreate) => {
    if (isCreate) {
      await api.adminCreateCompanyTemplateApi(
        form.templateName,
        form.masterFile,
        form.specialConfigFile,
        form.enTemplateFile,
        form.tableTemplateFile,
        form.qReverseTemplateFile,
        form.qReverseTextFile,
        form.customizedWPTemplateFile
      );
    } else {
      await api.adminUpdateCompanyTemplateApi(
        form.companyTemplateId,
        form.templateName,
        form.masterFile,
        form.specialConfigFile,
        form.enTemplateFile,
        form.tableTemplateFile,
        form.qReverseTemplateFile,
        form.qReverseTextFile,
        form.customizedWPTemplateFile
      );
    }

    closeEditTemplateModal();
    refetch();
  };

  const handleUpdateTemplateName = async (companyTemplateId, templateName) => {
    await api.adminUpdateCompanyTemplateNameApi(
      companyTemplateId,
      templateName
    );
    refetch();
  };

  const handleDeleteTemplate = async () => {
    closeConfirmDeleteTemplateDialog();

    if (selectedTemplate) {
      await api.adminDeleteCompanyTemplateApi(selectedTemplate.id);
    }

    refetch();
  };

  return (
    <Box>
      <ConfirmDialog
        id="delete-template-dialog"
        open={deleteTemplateDialogOpen}
        title={"Delete Company Template"}
        message={
          selectedTemplate &&
          `Are you sure you want to delete ${selectedTemplate.name}?`
        }
        onClose={closeConfirmDeleteTemplateDialog}
        onConfirm={handleDeleteTemplate}
      />
      <UploadTemplateModal
        isCreate={isCreateTemplate}
        onClose={closeEditTemplateModal}
        onSubmit={handleSubmitTemplate}
        open={editTemplateModalOpen}
        template={selectedTemplate}
      />
      <MaterialReactTable
        columns={columns}
        data={data?.data ?? []}
        rowCount={data?.totalRowCount ?? 0}
        state={{
          columnFilters,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
        enableGlobalFilter={false}
        manualFiltering
        manualPagination
        manualSorting
        onColumnFiltersChange={setColumnFilters}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            size: 150, //make actions column wider
          },
        }}
        positionActionsColumn="last"
        enableRowActions
        renderRowActions={({ row }) => [
          <Tooltip title="Update Template">
            <IconButton
              onClick={() => openEditTemplateModal(row.original, false)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Delete Template">
            <IconButton
              onClick={() => openConfirmDeleteTemplateDialog(row.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>,
        ]}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ width: "100%" }}>
            <Tooltip title="Create template" sx={{ float: "right" }}>
              <IconButton onClick={() => openEditTemplateModal(null, true)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </Box>
  );
};

const queryClient = new QueryClient();

const QueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <TemplateTable />
  </QueryClientProvider>
);

export default QueryProvider;
