import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import UploadArea from "component/UI/UploadArea/UploadArea";
import * as api from "api";
import LoadingButton from "@mui/lab/LoadingButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";

const SelectTableSheetDialog = (props) => {
  const { open, onClose, onConfirm, tableTabOptions } = props;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(false);
    }

    return () => {
      setLoading(false);
    };
  }, []);

  const handleConfirm = (sheet) => {
    setLoading(true);
    onConfirm(sheet);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        Confirm to generate document
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select a table sheet to generate a new document
        </DialogContentText>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            margin: "auto",
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          {tableTabOptions.map((tab) => (
            <LoadingButton
              key={tab.id}
              variant="outlined"
              loading={loading}
              onClick={() => handleConfirm(tab.id)}
            >
              {tab.value}
            </LoadingButton>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const GenerateReportFileForm = (props) => {
  const { projectId, onFetchProjectDetail, onBack, onNext } = props;

  const [uploadedFile, setUploadedFile] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState();
  const [loading, setLoading] = useState(false);

  const [tableTabOptions, setTableTabOptions] = useState([]);

  const handleNext = () => {
    onNext();
  };

  const handleBack = () => {
    onBack();
  };

  const validate = () => {
    return uploadedFile.length > 0;
  };

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleFileUpload = (files) => {
    setUploadedFile(files);
  };

  const handleCheckIntermediateFile = async () => {
    setLoading(true);
    try {
      let file = uploadedFile[0].file;
      await api.checkIntermediateFileApi(projectId, file);
      setUploadedFile([]);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleCheckIntermediateTableTabs = async () => {
    setLoading(true);
    try {
      let file = uploadedFile[0].file;
      const res = await api.checkIntermediateTableTabsApi(projectId, file);
      const options = res.data;
      setTableTabOptions(options);

      handleConfirmDialogOpen();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateReport = async (selectedTab) => {
    await api.generateDocumentApi(projectId, selectedTab);
    await onFetchProjectDetail();
    onNext();
  };

  return (
    <div>
      <SelectTableSheetDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleGenerateReport}
        tableTabOptions={tableTabOptions}
      />
      <Card elevation={0}>
        <CardHeader title={"Upload Vetted Intermediate File or customized WP"} />
        <CardContent
          sx={{ height: "300px", display: "flex", textAlign: "center" }}
        >
          <Box sx={{ margin: "auto", width: "100%" }}>
            <UploadArea
              files={uploadedFile}
              onUpdateFiles={handleFileUpload}
              allowMultiple={false}
              maxFiles={1}
              name={"please upload intermediate file or customized WP"}
              acceptedFileTypes={[".xlsx"]}
              fileSize={10485760}
            />
            <Box>
              <LoadingButton
                color={"warning"}
                variant={"outlined"}
                loading={loading}
                onClick={handleCheckIntermediateFile}
                disabled={!validate()}
                sx={{ margin: "5px" }}
              >
                Check intermediate file
              </LoadingButton>
              <LoadingButton
                variant={"outlined"}
                loading={loading}
                onClick={handleCheckIntermediateTableTabs}
                disabled={!validate()}
                sx={{ margin: "5px" }}
              >
                Generate final report
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
        <CardActions disableSpacing>
          <Tooltip title={"Back"}>
            <Button onClick={handleBack}>
              <NavigateBeforeIcon />
            </Button>
          </Tooltip>
          <Tooltip title={"Next"}>
            <Button onClick={handleNext}>
              <NavigateNextIcon />
            </Button>
          </Tooltip>
        </CardActions>
      </Card>
    </div>
  );
};

GenerateReportFileForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  onFetchProjectDetail: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default GenerateReportFileForm;
