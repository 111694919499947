export const Language = {
  EN: "EN",
  ZH: "ZH"
};

export const UserFilterStatus = {
  ACTIVE: "ACTIVE",
  ALL: "ALL"
};

export const ProjectFileType = {
  GENERATED_WP_FILE: "GENERATED_WP_FILE",
  GENERATED_REPORT_FILE: "GENERATED_REPORT_FILE"
};

export const ProjectStatus = {
  NOT_STARTED: "NOT_STARTED",
  STARTED: "STARTED",
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  COMPLETE: "COMPLETE",
  FAIL: "FAIL",
  RETRYING: "RETRYING",
};