import PropTypes from "prop-types";
import { useEffect } from "react";

const Logout = (props) =>  {
  const handleLogout = () => {
    props.onLogout();
  };

  useEffect(() => {
    if (props.countDown) {
      setTimeout(handleLogout, 3000);
    } else {
      handleLogout();
    }
  }, []);

  return (
    <div />
  );
}

Logout.propTypes = {
  onLogout: PropTypes.func.isRequired,
  countDown: PropTypes.number
};

export default Logout;