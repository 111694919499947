import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SubmitProjectForm from "./form/SubmitProjectForm";
import * as actions from "store/actions";
import * as api from "api";
import { useDispatch, useSelector } from "react-redux";
import PreviousDocumentFileForm from "./form/PreviousDocumentFileForm";
import { useNavigate } from "react-router-dom";
import { strToDate } from "share/utils";
import SelectNoteForm from "./form/SelectNoteForm";
import GenerateReportFileForm from "./form/GenerateReportFileForm";
import ExtractExcelPanel from "./form/ExtractExcelPanel";
import GenerateReportPanel from "./form/GenerateReportPanel";

const StepChild = (props) => {
  const { children, index, activeStep } = props;

  return index === activeStep && children;
};

const defaultForm = {
  company_name: "",
  company_name_zh: "",
  curr_from_date: null,
  curr_to_date: null,
  consolidate_report: false,
  currency_id: null,
  auditor: "",
  auditor_zh: "",
  include_zh: false,
  is_first_audit: false,
  prev_from_date: null,
  prev_to_date: null,
  prev_auditor: "",
  prev_auditor_zh: "",
  note_ids: [],
  user_upload_prev_file: null,
  user_upload_wp_file: null,
  wp_template_type: "USER_DEFINED",
};

const steps = [
  "Company Form",
  "Upload input files",
  "Select notes",
  "Generate intermediate file",
  "Upload vetted intermediate file",
  "Generate report",
];

const flows = {
  "USER_DEFINED": [0, 1, 2, 3, 4, 5],
  "CUSTOMIZED": [0, 4, 5],
}

const SubmitProjectStepper = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notes = useSelector((state) => state.auditor.notes);
  const { projectId } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [prevStep, setPrevStep] = useState(-1);
  const [skipped, setSkipped] = useState(new Set());
  const [flow, setFlow] = useState(flows["USER_DEFINED"]);

  const [project, setProject] = useState(defaultForm);

  const [loading, setLoading] = useState(false);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (skip_num) => {
    let skip_count = 0;

    if (skip_num) {
      for (let i = 0; i < skip_num; i++) {
        skip_count += 1;
        let nextStep = activeStep + skip_count;

        skipped.add(nextStep);
        setSkipped(new Set(skipped.values()));
      }
    }

    setPrevStep(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + skip_count + 1);
  };

  const handleBack = (skip_num) => {
    if (!skip_num) {
      skip_num = 0;
    }

    let prevStep = activeStep - (1 + skip_num);

    while (skipped.has(prevStep) && prevStep > 0) {
      setActiveStep(prevStep);
      prevStep = prevStep - 1;
    }

    setActiveStep(prevStep);
  };

  const processProjectDate = (project) => {
    project["curr_from_date"] = strToDate(project["curr_from_date"]);
    project["curr_to_date"] = strToDate(project["curr_to_date"]);
    project["prev_from_date"] = strToDate(project["prev_from_date"]);
    project["prev_to_date"] = strToDate(project["prev_to_date"]);

    return project;
  };

  const fetchProjectDetail = () => {
    return api.getProjectDetailApi(projectId).then((res) => {
      let project = processProjectDate(res.data);

      setProject((prevState) => ({
        ...prevState,
        ...project,
      }));

      return project;
    });
  };

  const fetchOnStart = async () => {
    setLoading(true);

    try {
      await fetchProjectDetail();

      setLoading(false);
    } catch {
      navigate("/");
    }
  };

  useEffect(() => {
    setProject((form) => ({
      ...form,
      selectedNotes: notes.filter((note) => note.always_exist),
    }));
  }, [notes]);

  useEffect(() => {
    fetchOnStart();
  }, [projectId]);

  useEffect(() => {
    dispatch(actions.auditorGetProjectConfig(projectId));
  }, []);

  useEffect(() => {
    setFlow(flows[project.wp_template_type])
  }, [project.wp_template_type])

  const renderStep = (label, index) => {
    const stepProps = {};
    const labelProps = {};

    if (isStepSkipped(index)) {
      stepProps.completed = false;
    }

    if (flow.includes(index)) {
      return (
        <Step key={label} {...stepProps}>
          <StepLabel {...labelProps}>{label}</StepLabel>
        </Step>
      );
    }
  }

  return loading ?
    (<Box
      sx={{
        display: "flex",
        margin: "auto",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>)
    :
    (<Grid container>
      <Grid item sm={12}>
        <Box sx={{ display: "flex", alignItems: "center", height: "30px" }}>
          <Button onClick={() => navigate("/")}>
            <ChevronLeftIcon />
            <Typography>Home</Typography>
          </Button>
          <Typography
            variant={"h5"}
            sx={{ textAlign: "center", flex: "0.87 0 auto" }}
          >
            {project && project["project_name"]}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Paper
          elevation={3}
          sx={{
            padding: "10px",
            marginTop: "30px",
            borderRadius: "10px",
          }}
        >
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{ marginTop: 2 }}
          >
            {steps.map(renderStep)}
          </Stepper>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          sx={{
            minHeight: "300px",
            height: "100%",
            margin: "auto",
            marginTop: "20px",
            padding: "10px 10px 0 10px",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ margin: "auto" }}>
            <StepChild index={0} activeStep={flow[activeStep]}>
              <SubmitProjectForm
                projectId={projectId}
                project={project}
                onFetchProjectDetail={fetchProjectDetail}
                onNext={handleNext}
              />
            </StepChild>
            <StepChild index={1} activeStep={flow[activeStep]}>
              <PreviousDocumentFileForm
                projectId={projectId}
                project={project}
                onFetchProjectDetail={fetchProjectDetail}
                onNext={handleNext}
                onBack={handleBack}
              />
            </StepChild>
            <StepChild index={2} activeStep={flow[activeStep]}>
              <SelectNoteForm
                projectId={projectId}
                selectedNoteIds={project["note_ids"]}
                isFirstAudit={project["is_first_audit"]}
                prevWPFileExist={project["user_upload_wp_file"]}
                prevFileExist={project["user_upload_prev_file"]}
                isUseCustomizedWP={project["wp_template_type"] === "CUSTOMIZED"}
                onFetchProjectDetail={fetchProjectDetail}
                onNext={handleNext}
                onBack={handleBack}
              />
            </StepChild>
            <StepChild index={3} activeStep={flow[activeStep]}>
              <ExtractExcelPanel
                projectId={projectId}
                project={project}
                onFetchProjectDetail={fetchProjectDetail}
                onNext={handleNext}
                onBack={handleBack}
                initLoading={prevStep === 2}
              />
            </StepChild>
            <StepChild index={4} activeStep={flow[activeStep]}>
              <GenerateReportFileForm
                projectId={projectId}
                onFetchProjectDetail={fetchProjectDetail}
                onNext={handleNext}
                onBack={handleBack}
              />
            </StepChild>
            <StepChild index={5} activeStep={flow[activeStep]}>
              <GenerateReportPanel
                projectId={projectId}
                project={project}
                onFetchProjectDetail={fetchProjectDetail}
                onNext={handleNext}
                onBack={handleBack}
              />
            </StepChild>
          </Box>
        </Paper>
      </Grid>
    </Grid>
    );
};

SubmitProjectStepper.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default SubmitProjectStepper;