import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import configureStore from "./store/store";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "filepond/dist/filepond.min.css";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { ToastContainer } from "react-toastify";

const store = configureStore();
const theme = createTheme();


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={process.env.REACT_APP_DP_WEB_ROOT}>
        <ThemeProvider theme={theme}>
          <ToastContainer autoClose={7000} />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
