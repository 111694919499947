import PropTypes from "prop-types";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Popover } from "@mui/material";
import { useState } from "react";

const ConfirmPopover = (props) =>  {
  const { title, message, anchorEl, onConfirm, onClose } = props;
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = async () => {
    setDisableSubmit(true);
    try {
      await onConfirm();
    } catch (e) {
    }

    setDisableSubmit(false);
  };

  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} autoFocus disabled={disableSubmit}>
          Confirm
        </Button>
      </DialogActions>
    </Popover>
  );
}

ConfirmPopover.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  anchorEl: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ConfirmPopover;