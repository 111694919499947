import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { toReadable } from "share/utils";

const HeaderStyle = {
  fontWeight: "bold",
};

const UserSubTable = (props) => {
  const createRow = (user) => {
    return (
      <TableRow key={user.user_id}>
        <TableCell align="center">{user.email}</TableCell>
        <TableCell align="center">{user.name}</TableCell>
        <TableCell align="center">{toReadable(user.account_type)}</TableCell>
        <TableCell align="center">{user.signup_date}</TableCell>
        <TableCell align="center">
          {user.deleted ? "Deleted" : "Active"}
        </TableCell>
        <TableCell align="center">
          <Box>
            <Tooltip title="Edit User">
              <IconButton
                onClick={() => props.onSelectUser(user, props.company, false)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete User">
              <IconButton onClick={() => props.onDeleteUser(user)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer
      component={Paper}
      className="no-scroll-bar"
      sx={{
        maxHeight: "500px",
        overflow: "scroll",
      }}
      elevation={3}
    >
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={HeaderStyle}>
              Email
            </TableCell>
            <TableCell align="center" style={HeaderStyle}>
              Name
            </TableCell>
            <TableCell align="center" style={HeaderStyle}>
              Account Type
            </TableCell>
            <TableCell align="center" style={HeaderStyle}>
              Create date
            </TableCell>
            <TableCell align="center" style={HeaderStyle}>
              Account status
            </TableCell>
            <TableCell align="center" style={HeaderStyle}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ maxHeight: "300px", overflow: "scroll" }}>
          {props.users.map((user) => createRow(user))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

UserSubTable.propTypes = {
  users: PropTypes.array.isRequired,
  company: PropTypes.object.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
};

export default UserSubTable;
