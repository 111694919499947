import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip, Typography
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import * as api from "api";

const EditCompanyModal = (props) => {
  const { company, open, isCreate, onClose, onSubmit } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [companyTemplates, setCompanyTemplates] = useState([""]);

  const [form, setForm] = useState({
    companyId: null,
    companyName: "",
    companyTemplateIds: [],
    cpaList: [],
  });

  const handleFetchCompanyTemplates = async () => {
    let res = await api.adminGetCompanyTemplatesApi();
    let companyTemplates = res.data?.data ?? [];
    setCompanyTemplates(companyTemplates);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    let cpaList = form.cpaList.filter((ele) => ele.trim() !== "")
    let submitForm = {...form, cpaList}
    try {
      await onSubmit(submitForm, isCreate);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleChangeCpa = (index) => (e) => {
    setForm((prevFormData) => {
      prevFormData.cpaList[index] = e.target.value;
      return { ...prevFormData };
    });
  };

  const handleDeleteCpa = (index) => {
    let cpaList = form.cpaList;
    if (index !== -1) {
      cpaList.splice(index, 1);
    }

    setForm((prevFormData) => ({
      ...prevFormData,
    }));
  };

  const handleAddCpaField = () => {
    setForm((prevFormData) => {
      prevFormData.cpaList.push("");
      return { ...prevFormData };
    });
  };

  const handleChange = (prop, isBool) => (e) => {
    let value;
    if (isBool) {
      value = e.target.value === "true";
    } else {
      value = e.target.value;
    }

    setForm((prevFormData) => {
      prevFormData[prop] = value;
      return { ...prevFormData };
    });
  };

  const validate = () => {
    return !isLoading && form.companyName && form.companyTemplateIds.length > 0;
  };

  useEffect(() => {
    if (company != null) {
      let companyTemplateIds = company["company_templates"].map(
        (template) => template.id
      );
      let form = {
        companyId: company["company_id"],
        companyName: company.company,
        companyTemplateIds,
        cpaList: [...company["cpa_list"], ""],
      };

      setForm((prevForm) => {
        return {
          ...prevForm,
          ...form,
        };
      });
    }
  }, [company]);

  useEffect(() => {
    if (!open) {
      setForm({
        companyId: null,
        companyName: "",
        companyTemplateIds: [],
        cpaList: [],
      });
    } else {
      handleFetchCompanyTemplates();
    }
    setIsLoading(false);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Company Details</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <TextField
                label="Company Name"
                id="company-name"
                value={form.companyName}
                placeholder="Enter name of Company"
                onChange={handleChange("companyName")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <InputLabel id="select-company-template-label">
                Company Templates
              </InputLabel>
              <Select
                labelId="select-company-template-label"
                label="Company Template"
                multiple
                value={form.companyTemplateIds}
                renderValue={(selected) => {
                  let indexMap = companyTemplates.map((ele) => ele.id);
                  return selected
                    .map(
                      (s) =>
                        companyTemplates.at(indexMap.indexOf(s))?.template ?? ""
                    )
                    .join(", ");
                }}
                onChange={handleChange("companyTemplateIds")}
              >
                {companyTemplates.map((ele) => {
                  return (
                    <MenuItem value={ele.id}>
                      <Checkbox
                        checked={form.companyTemplateIds.indexOf(ele.id) > -1}
                      />
                      <ListItemText primary={ele.template} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <Typography variant={"h6"}>CPA</Typography>
              {form.cpaList.map((cpa, index) => (
                <OutlinedInput
                  id={`cpa_field_${index}`}
                  value={cpa}
                  onChange={handleChangeCpa(index)}
                  placeholder="CPA"
                  sx={{ marginTop: "5px" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleDeleteCpa(index)}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              ))}
            </FormControl>
            <Box sx={{ float: "right", marginTop: "5px"}}>
              <Tooltip title={"Add new CPA"}>
                <IconButton onClick={handleAddCpaField}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={handleConfirm}
          disabled={!validate()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditCompanyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isCreate: PropTypes.bool.isRequired,
  company: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EditCompanyModal;
