import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import * as api from "api";
import { ProjectFileType, ProjectStatus } from "share/constants";
import ArticleIcon from "@mui/icons-material/Article";
import ErrorIcon from "@mui/icons-material/Error";
import { toReadable } from "share/utils";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const downloadAreaStyle = {
  padding: "50px",
  background: "#F1F0EF",
  borderRadius: "10px",
  display: "inline-block",
};

const subTitleStyle = {
  marginTop: "15px",
};

const GenerateReportPanel = (props) => {
  const { project, projectId, onBack, onFetchProjectDetail } = props;

  const [intervalId, setIntervalId] = useState(null);

  const handleBack = () => {
    onBack();
  };

  const handleDownloadReport = async () => {
    await api.downloadProjectFileApi(
      projectId,
      ProjectFileType.GENERATED_REPORT_FILE
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      onFetchProjectDetail();
    }, 3000);

    setIntervalId(intervalId);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let generateStatus = project?.["generate_document_status"]?.status;
    if (
      generateStatus === ProjectStatus.COMPLETE ||
      generateStatus === ProjectStatus.FAIL
    ) {
      clearInterval(intervalId);
    }
  }, [project]);

  return (
    <Card elevation={0}>
      <CardHeader title={"Generate Report"} />
      <CardContent
        sx={{ height: "300px", display: "flex", textAlign: "center" }}
      >
        {(() => {
          switch (project?.["generate_document_status"].status) {
            case ProjectStatus.COMPLETE:
              return (
                <Box sx={{ margin: "auto" }}>
                  <ButtonBase
                    style={downloadAreaStyle}
                    onClick={handleDownloadReport}
                  >
                    <SvgIcon fontSize="large">
                      <ArticleIcon />
                    </SvgIcon>
                    <Typography variant={"body1"} style={subTitleStyle}>
                      Please click here to download the final report
                    </Typography>
                  </ButtonBase>
                </Box>
              );
            case ProjectStatus.FAIL:
              return (
                <Box sx={{ margin: "auto" }}>
                  <SvgIcon fontSize="large">
                    <ErrorIcon />
                  </SvgIcon>
                  <Typography variant={"body1"} style={subTitleStyle}>
                    Fail to generate report. Please retry.
                  </Typography>
                </Box>
              );
            case ProjectStatus.NOT_STARTED:
              return (
                <Box sx={{ margin: "auto" }}>
                  <SvgIcon fontSize="large">
                    <ErrorIcon />
                  </SvgIcon>
                  <Typography variant={"body1"} style={subTitleStyle}>
                    Please upload the vetted intermediate file to generate the
                    final report
                  </Typography>
                </Box>
              );
            default:
              return (
                <Box sx={{ margin: "auto" }}>
                  <CircularProgress />
                  <Typography variant={"body1"} style={subTitleStyle}>
                    {project?.["generate_document_status"]?.status
                      ? toReadable(project["generate_document_status"].status)
                      : "Processing"}
                  </Typography>
                </Box>
              );
          }
        })()}
      </CardContent>
      <CardActions disableSpacing>
        <Tooltip title={"Back"}>
          <Button onClick={handleBack}>
            <NavigateBeforeIcon />
          </Button>
        </Tooltip>
        <Tooltip title={"Next"}>
          <Button disabled={true}>
            <NavigateNextIcon />
          </Button>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

GenerateReportPanel.propTypes = {
  projectId: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  onFetchProjectDetail: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default GenerateReportPanel;
