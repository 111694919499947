import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const CreateProjectDialog = (props) => {
  const { templates, open, onSubmit, onClose } = props;
  const [projectName, setProjectName] = useState("");
  const [templateId, setTemplateId] = useState(null);
  const [workingPaperType, setWorkingPaperType] = useState("USER_DEFINED");
  const [loading, setLoading] = useState(false);
  const [isShowCustomizedWPCheckBox, setIsShowCustomizedWPCheckBox] = useState(false);

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleTemplateChange = (e) => {
    setTemplateId(e.target.value);
    setIsShowCustomizedWPCheckBox(templates.find(template => template.id === e.target.value).available_customized_wp);
    setWorkingPaperType("USER_DEFINED");
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit(projectName, templateId, workingPaperType);
    } catch (e) {
    }

    setLoading(false);
  };

  const handleCustomizedWPChange = (e) => {
    setWorkingPaperType(e.target.checked ? "CUSTOMIZED" : "USER_DEFINED");
  };

  const validate = () => {
    return projectName !== "" && templateId !== null;
  };


  useEffect(() => {
    if (open) {
      setProjectName("");
      setWorkingPaperType("USER_DEFINED");
      if (loading) {
        setLoading(false);
      }
    }
  }, [open]);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Create new project</DialogTitle>
      <DialogContent sx={{ width: "400px", paddingY: "0px" }}>
        <FormControl fullWidth sx={{ marginBottom: "20px" }}>
          <TextField
            id="create-project-name-field"
            label="Project name"
            fullWidth
            autoFocus
            margin={"dense"}
            value={projectName}
            placeholder="Enter name of project"
            onChange={handleProjectNameChange}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="template-label">Template</InputLabel>
          <Select
            labelId="template-label"
            id="project-template-field"
            value={templateId}
            label="Template"
            onChange={handleTemplateChange}
          >
            {
              templates && templates.map(template => (
                <MenuItem value={template.id}>{template.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        {isShowCustomizedWPCheckBox &&
        <FormControlLabel
          control={<Checkbox checked={workingPaperType === "CUSTOMIZED"} onChange={handleCustomizedWPChange} />}
          label="Use customized WP?"
          sx={{ marginTop: "20px" }}
        />}
      </DialogContent>
      <DialogActions>
        <LoadingButton autoFocus onClick={handleSubmit} disabled={!validate()} loading={loading}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

CreateProjectDialog.propTypes = {
  templates: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CreateProjectDialog;