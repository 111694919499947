import * as actionTypes from "store/actions/actionTypes";

const initialState = {
  isLoading: false,
  extractedExcelFile: null,
  generatedDocument: null,
  notes: [],
  currencies: [],
  cpa_options: [],
};

const projectGetProjectConfigStart = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const projectGetProjectConfigSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    currencies: action.currencies,
    notes: action.notes,
    cpa_options: action.cpa_options,
  };
};

const projectGetProjectConfigFail = (state, action) => {
  return {
    ...state,
    isLoading: false,
    errorMessage: action.errorMessage,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROJECT_GET_PROJECT_CONFIG_START:
      return projectGetProjectConfigStart(state, action);
    case actionTypes.PROJECT_GET_PROJECT_CONFIG_SUCCESS:
      return projectGetProjectConfigSuccess(state, action);
    case actionTypes.PROJECT_GET_PROJECT_CONFIG_FAIL:
      return projectGetProjectConfigFail(state, action);
    default:
      return state;
  }
};

export default reducer;
