import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Delete, KeyboardArrowRight } from "@mui/icons-material";
import * as api from "api";
import LoadingButton from "@mui/lab/LoadingButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SkipNextIcon from "@mui/icons-material/SkipNext";

const listItemStyle = {
  height: 56,
};

const union = (a, b) => {
  return [...new Set([...a, ...b])];
};

const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

const SelectNoteForm = (props) => {
  const { selectedNoteIds, isFirstAudit, prevFileExist, prevWPFileExist } =
    props;

  const notes = useSelector((state) => state.auditor.notes);
  const defaultNotes = notes.filter((note) => note.always_exist);
  const selectedNotes = notes.filter((note) =>
    selectedNoteIds.includes(note.value)
  );

  const [checked, setChecked] = useState([]);
  const [selected, setSelected] = useState([]);
  const [unSelected, setUnSelected] = useState([]);

  const [autoSelectLoading, setAutoSelectLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    handleSelect(union(defaultNotes, selectedNotes));
  }, [notes, selectedNoteIds]);

  const unselectChecked = intersection(checked, unSelected);

  const sortTables = (list) => {
    list.sort((a, b) => {
      return a.value - b.value;
    });
    return list;
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAutoSelect = async () => {
    setAutoSelectLoading(true);
    try {
      let res = await api.getNotesApi(props.projectId);
      let noteIds = res.data.notes;
      let checkedNotes = unSelected.filter((note) =>
        noteIds.includes(note.value)
      );

      setChecked(checkedNotes);
    } catch (e) { }

    setAutoSelectLoading(false);
  };

  const handleSelect = (selected) => {
    selected = sortTables(selected);
    const unSelected = not(notes, selected);
    setSelected(selected);
    setUnSelected(unSelected);
    setChecked([]);
  };

  const handleAllUnselected = () => {
    handleSelect(defaultNotes);
  };

  const handleSelectChecked = () => {
    handleSelect(union(selected, unselectChecked));
  };

  const handleDelete = (item) => {
    handleSelect(not(selected, [item]));
  };

  const validate = () => {
    return selected.length > 0;
  };

  const handleConfirm = async () => {
    setSubmitLoading(true);
    try {
      let ids = selected.map((note) => note.value);

      if (ids.sort().toString() !== selectedNoteIds.sort().toString()) {
        await api.selectNotesApi(props.projectId, ids);
      }

      if (!props.isUseCustomizedWP) {
        await api.extractExcelApi(props.projectId);
      }
      await props.onFetchProjectDetail();
      props.onNext();
    } catch (e) {
      setSubmitLoading(false);
    }
  };

  const handleSkip = async () => {
    setSubmitLoading(true);
    try {
      let ids = selected.map((note) => note.value);

      if (ids.sort().toString() !== selectedNoteIds.sort().toString()) {
        await api.selectNotesApi(props.projectId, ids);
      }

      await props.onFetchProjectDetail();
      props.onNext(1);
    } catch (e) {
      setSubmitLoading(false);
    }
  };

  const handleBack = () => {
    props.onBack();
  };

  const presetNotes = useMemo(
    () => notes.filter((table) => table.always_exist),
    [notes]
  );

  const validateNext = () => {
    if (props.wp_template_type === "USER_DEFINED") {
      if (isFirstAudit) {
        return prevWPFileExist;
      } else {
        return prevFileExist && prevWPFileExist;
      }
    }
    return true
  }

  return (
    <Card elevation={0}>
      <CardHeader title={"Select Notes"} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Card elevation={3} sx={{ height: "70vh", overflow: "auto" }}>
              <CardHeader sx={{ px: 2, py: 1 }} title="Options" />
              <Divider />
              <List dense component="div" role="list">
                {unSelected.map((item, i) => {
                  const labelId = `transfer-list-item-${item.name}-label`;

                  return (
                    <ListItemButton
                      key={item.value}
                      onClick={handleToggle(item)}
                      style={listItemStyle}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={checked.indexOf(item) !== -1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={item.name} />
                    </ListItemButton>
                  );
                })}
                <ListItem />
              </List>
            </Card>
          </Grid>
          <Grid item xs={2} sx={{ display: "flex" }}>
            <Stack spacing={2} sx={{ margin: "auto" }}>
              <Button
                fullWidth
                variant="outlined"
                size="small"
                onClick={handleAllUnselected}
                disabled={selected.length <= presetNotes.length}
                aria-label="move all right"
              >
                {"Reset"}
              </Button>
              {!props.isUseCustomizedWP &&
                <LoadingButton
                  fullWidth
                  loading={autoSelectLoading}
                  variant="outlined"
                  size="small"
                  onClick={handleAutoSelect}
                  disabled={isFirstAudit || !prevFileExist}
                  aria-label="select notes from last year report"
                >
                  {"AUTO SELECT"}
                </LoadingButton>}
              <Button
                fullWidth
                variant="outlined"
                size="small"
                onClick={handleSelectChecked}
                disabled={unselectChecked.length === 0}
                aria-label="move selected left"
              >
                {"Add"}
                <KeyboardArrowRight
                  sx={{
                    position: "absolute",
                    right: 0,
                  }}
                />
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <Card elevation={3} sx={{ height: "70vh", overflow: "auto" }}>
              <CardHeader
                sx={{ px: 2, py: 1 }}
                title="Notes to appear in the report"
              />
              <Divider />
              <List dense component="div" role="list">
                {selected.map((item, i) => {
                  const labelId = `transfer-list-item-${item.name}-label`;
                  let text = `${i + 1}. ${item.name}`;

                  let style = item.always_exist ? { visibility: "hidden" } : {};

                  return (
                    <ListItemButton key={item.value} style={listItemStyle}>
                      <ListItemIcon>
                        <IconButton
                          style={style}
                          onClick={() => handleDelete(item)}
                        >
                          <Delete />
                        </IconButton>
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={text} />
                    </ListItemButton>
                  );
                })}
                <ListItem />
              </List>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Tooltip title={"Back"}>
          <Button onClick={handleBack}>
            <NavigateBeforeIcon />
          </Button>
        </Tooltip>
        <Tooltip title={"Next"}>
          <LoadingButton
            loading={submitLoading}
            disabled={!validate() || !validateNext()}
            onClick={() => handleConfirm()}
          >
            <NavigateNextIcon />
          </LoadingButton>
        </Tooltip>
        {!props.isUseCustomizedWP &&
          < Tooltip title={"Skip to step 5"}>
            <LoadingButton
              loading={submitLoading}
              disabled={!validate()}
              onClick={handleSkip}
            >
              <SkipNextIcon />
            </LoadingButton>
          </Tooltip>
        }
      </CardActions>
    </Card >
  );
};
SelectNoteForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  selectedNoteIds: PropTypes.array.isRequired,
  isFirstAudit: PropTypes.bool.isRequired,
  prevWPFileExist: PropTypes.bool.isRequired,
  prevFileExist: PropTypes.bool.isRequired,
  onFetchProjectDetail: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default SelectNoteForm;
