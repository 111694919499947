import * as actionTypes from "./actionTypes";
import { urljoin } from "../../share/utils";
import axios from "../../share/axios";
import { toast } from "react-toastify";

const userAuthenticationFail = () => {
  return {
    type: actionTypes.USER_AUTHENTICATION_FAIL
  };
};

const getUserDetailSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_DETAIL_SUCCESS,
    name: data.name,
    username: data.username,
    email: data.email,
    phone: data.phone,
    isAdmin: data.is_admin,
    company: data.company
  };
};

export const getUserDetail = () => (dispatch) => {
  let url = urljoin(process.env.REACT_APP_AUDITOR_SERVICE, "user");

  axios
    .get(url)
    .then((response) => {
      dispatch(getUserDetailSuccess(response.data));
    })
    .catch((error) => {
      console.error(error)
        toast.error(error.response.data.message, {
          autoClose: 5000
        });

        if (error.response.status === 403) {
          dispatch(userAuthenticationFail());
        }
      }
    );
};