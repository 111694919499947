import axios from "axios";
import { toast } from "react-toastify";

/**
 * @type {{getToken: () => Promise<String>}}
 */
export const axiosConfig = {
  getToken: undefined
};

/**
 * @type {Promise<string>}
 */
let tokenPromise = null;
export const getToken = async () => {
  return tokenPromise;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_DP_SERVICE
});

const interceptRefreshToken = async (originalRequest) =>  {
  // auth0 client already handled concurrent token request
  const accessToken = await axiosConfig.getToken();
  originalRequest.headers.Authorization = `Bearer ${accessToken}`;
  return axios(originalRequest);
}

const errorResponseHandler = async (error) =>  {
  const originalRequest = error.config;
  // if has response show the error
  if (error.response) {
    if (error.response.status === 401) {
      return interceptRefreshToken(originalRequest);
    }
  }
  return Promise.reject(error);
}

// apply interceptor on response
instance.interceptors.response.use(
  (response) => response,
  errorResponseHandler
);

instance.interceptors.request.use(
  async (config) => {
    const token = await axiosConfig.getToken();
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
