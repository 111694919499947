import { Navigate } from "react-router-dom";

const GuardedRoute = (props) =>  {
  const { hasAccess, children } = props;

  if (!hasAccess) {
    return <Navigate to="/" />;
  }

  return children;
};

GuardedRoute.propTypes = {
  hasAccess: true
};

export default GuardedRoute;
