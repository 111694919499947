import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  IconButton
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import * as api from "api";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from '@mui/icons-material/Download';

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

const getFromDate = (toDate) => {
  const fromDate = new Date(toDate);
  fromDate.setTime(fromDate.getTime() + DAY_IN_MILLISECONDS);
  fromDate.setFullYear(fromDate.getFullYear() - 1);
  return fromDate;
};

const getToDate = (fromDate) => {
  const toDate = new Date(fromDate);
  toDate.setFullYear(toDate.getFullYear() + 1);
  toDate.setTime(toDate.getTime() - DAY_IN_MILLISECONDS);
  return toDate;
};

const fromDateUpdateNext = (formData) => {
  const newFormData = {
    ...formData,
    curr_to_date: getToDate(formData["curr_from_date"]),
  };
  return newFormData;
};

const prevToDateUpdateNext = (formData) => {
  const fromDate = new Date(formData["prev_to_date"]);
  fromDate.setTime(fromDate.getTime() + DAY_IN_MILLISECONDS);
  const newFormData = {
    ...formData,
    curr_from_date: fromDate,
  };
  return fromDateUpdateNext(newFormData);
};

const prevFromDateUpdateNext = (formData) => {
  const newFormData = {
    ...formData,
    prev_to_date: getToDate(formData["prev_from_date"]),
  };
  return prevToDateUpdateNext(newFormData);
};

const prevToDateUpdatePrev = (formData) => {
  const newFormData = {
    ...formData,
    prev_from_date: getFromDate(formData["prev_to_date"]),
  };
  return newFormData;
};

const fromDateUpdatePrev = (formData) => {
  const prevToDate = new Date(formData["curr_from_date"]);
  prevToDate.setTime(prevToDate.getTime() - DAY_IN_MILLISECONDS);
  const newFormData = {
    ...formData,
    prev_to_date: prevToDate,
  };
  return prevToDateUpdatePrev(newFormData);
};

const toDateUpdatePrev = (formData) => {
  const newFormData = {
    ...formData,
    curr_from_date: getFromDate(formData["curr_to_date"]),
  };
  return fromDateUpdatePrev(newFormData);
};

const updateDate = (formData, dateKey) => {
  switch (dateKey) {
    case "prev_from_date": {
      return prevFromDateUpdateNext(formData);
    }

    case "prev_to_date": {
      return prevToDateUpdateNext(prevToDateUpdatePrev(formData));
    }

    case "curr_from_date": {
      return fromDateUpdateNext(fromDateUpdatePrev(formData));
    }

    case "curr_to_date": {
      return toDateUpdatePrev(formData);
    }

    default: {
      console.warn(`Unexpected datakey [${dateKey}]`);
      return formData;
    }
  }
};

const fieldNames = [
  "prev_auditor_zh",
  "prev_auditor",
  "prev_to_date",
  "prev_from_date",
  "is_first_audit",
  "include_zh",
  "auditor_zh",
  "auditor",
  "currency_id",
  "curr_to_date",
  "curr_from_date",
  "company_name",
];

const SubmitProjectForm = (props) => {
  const currencies = useSelector((state) => state.auditor.currencies);
  const cpa_options = useSelector((state) => state.auditor.cpa_options);

  const { project } = props;

  const [formData, setFormData] = useState({ ...project });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      ...project,
    }));
  }, [project]);

  useEffect(() => {
    if (formData.auditor == null) {
      formData.auditor = cpa_options.length > 0 ? cpa_options[0] : "";
    }

    if (formData.currency_id == null) {
      formData.currency_id = currencies.length > 0 ? currencies[0].id : null;
    }

    setFormData({ ...formData });
  }, [cpa_options, currencies]);

  const isFormUpdated = () => {
    for (let name of fieldNames) {
      if (formData[name] !== project[name]) {
        return true;
      }
    }

    return false;
  };

  const handleChange = (prop, isBool) => (e) => {
    let value;
    if (isBool) {
      value = e.target.value === "true";
    } else {
      value = e.target.value;
    }

    setFormData((prevFormData) => {
      prevFormData[prop] = value;
      return { ...prevFormData };
    });
  };

  const handleAutoComplete = (prop) => (newValue) => {
    setFormData((prevFormData) => {
      prevFormData[prop] = newValue;
      return { ...prevFormData };
    });
  };

  const handleDownloadCustomizedWP = async () => {
    await api.downloadCustomizedWPApi(project.template_id);
  };

  const auditorOptions = useMemo(() => {
    const inputValue = formData["auditor"];
    if (inputValue) {
      if (!cpa_options.includes(inputValue)) {
        return [inputValue, ...cpa_options];
      }
    }

    return [...cpa_options];
  }, [formData["auditor"], cpa_options]);

  const updateDateFormData = (prop, value) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      newFormData[prop] = value;

      const dateKeys = [
        "curr_from_date",
        "curr_to_date",
        "prev_from_date",
        "prev_to_date",
      ];

      const prevDatesFilled = dateKeys
        .map((key) => prevFormData[key])
        .filter((value) => !!value)
        .reduce((acc, cur) => acc + 1, 0);
      console.log(prevDatesFilled);
      console.log(value);

      // Filling by select (0) or by entering (1)
      if (prevDatesFilled < 2 && !isNaN(value)) {
        if (value.getFullYear() >= 1970) {
          return updateDate(newFormData, prop);
        }
      }

      return newFormData;
    });
  };

  const handleDateOnChange = (prop) => (value, keyboardInputValue) => {
    if (value && !isNaN(value) && keyboardInputValue) {
      updateDateFormData(prop, value);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [prop]: value,
      }));
    }
  };

  const handleDateOnAccept = (prop) => (value) => {
    updateDateFormData(prop, value);
  };

  const handleSubmit = async () => {
    if (isFormUpdated()) {
      setLoading(true);
      try {
        await api.updateProjectDetailApi(props.projectId, formData);
        await props.onFetchProjectDetail();

        props.onNext();
      } catch (e) {
      } finally {
        setLoading(false);
      }
    } else {
      props.onNext();
    }
  };

  const handleSkip = async () => {
    if (isFormUpdated()) {
      setLoading(true);
      try {
        await api.updateProjectDetailApi(props.projectId, formData);
        await props.onFetchProjectDetail();

        props.onNext(1);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    } else {
      props.onNext(1);
    }
  };

  const validate = () => {
    const isCompanyNameValid = formData["company_name"]?.trim() !== "";
    const isFromDateValid =
      formData["curr_from_date"] !== null &&
      formData["curr_from_date"] !== undefined &&
      formData["curr_from_date"] <= formData["curr_to_date"];
    const isToDateValid =
      formData["curr_to_date"] !== null &&
      formData["curr_to_date"] !== undefined &&
      formData["curr_from_date"] <= formData["curr_to_date"];
    const isAuditorNameValid = formData["auditor"]?.trim() !== "";
    const isCurrencyValid = formData["currency_id"] !== null;

    const isPrevFromDateValid =
      formData["is_first_audit"] ||
      (formData["prev_from_date"] !== null &&
        formData["prev_from_date"] !== undefined &&
        formData["prev_from_date"] <= formData["prev_to_date"]);
    const isPrevToDateValid =
      formData["is_first_audit"] ||
      (formData["prev_to_date"] !== null &&
        formData["prev_to_date"] !== undefined &&
        formData["prev_from_date"] <= formData["prev_to_date"] &&
        formData["prev_to_date"] <= formData["curr_from_date"]);

    // for chinese only
    const isCompanyNameZhValid =
      !formData["include_zh"] || formData["company_name_zh"].trim() !== "";
    const isAuditorNameZhValid =
      !formData["include_zh"] || formData["auditor_zh"].trim() !== "";

    return (
      isCompanyNameValid &&
      isFromDateValid &&
      isToDateValid &&
      isAuditorNameValid &&
      isCurrencyValid &&
      isPrevFromDateValid &&
      isPrevToDateValid &&
      isCompanyNameZhValid &&
      isAuditorNameZhValid
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Card elevation={0}>
        <CardHeader title={"Project Details"} />
        <CardContent>
          <Box component="form">
            <Grid container>
              <Grid item xs={6}>
                <FormControl sx={{ m: 1, width: "90%" }}>
                  <Typography variant="h6">Template</Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6" color="#6A737C">
                      {project.template}
                    </Typography>
                    {project.wp_template_type === "CUSTOMIZED" &&
                      <Tooltip title={"Download"}>
                        <IconButton onClick={handleDownloadCustomizedWP}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </Box>
                </FormControl>
                <FormControl sx={{ m: 1, width: "90%" }}>
                  <Typography variant="h6">Company Name</Typography>
                  <OutlinedInput
                    id="company-name"
                    value={formData["company_name"]}
                    placeholder="Enter name of Company being audited"
                    onChange={handleChange("company_name")}
                    disabled={formData["lock"]}
                  />
                </FormControl>
              </Grid>
              {formData["includeZh"] && (
                <Grid item xs={6}>
                  <FormControl sx={{ m: 1, width: "90%" }}>
                    <Typography variant="h6">Company Name (Chinese)</Typography>
                    <OutlinedInput
                      id="company-name-zh"
                      value={formData["company_name_zh"]}
                      placeholder="Enter name of Company being audited in Chinese"
                      onChange={handleChange("company_name_zh")}
                      disabled={formData["lock"]}
                    />
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                      CURRENT reporting period
                    </Typography>
                    <Box sx={{ display: "inline-flex" }}>
                      <DesktopDatePicker
                        label="From date"
                        inputFormat="MM/dd/yyyy"
                        value={formData["curr_from_date"]}
                        maxDate={formData["curr_to_date"] ?? undefined}
                        onChange={handleDateOnChange("curr_from_date")}
                        onAccept={handleDateOnAccept("curr_from_date")}
                        renderInput={(params) => <TextField {...params} />}
                        disabled={formData["lock"]}
                      />
                      <Typography
                        variant="body1"
                        sx={{ margin: " 15px 30px 0 30px" }}
                      >
                        To
                      </Typography>
                      <DesktopDatePicker
                        label="To date"
                        inputFormat="MM/dd/yyyy"
                        value={formData["curr_to_date"]}
                        minDate={formData["curr_from_date"] ?? undefined}
                        onChange={handleDateOnChange("curr_to_date")}
                        onAccept={handleDateOnAccept("curr_to_date")}
                        renderInput={(params) => <TextField {...params} />}
                        disabled={formData["lock"]}
                      />
                    </Box>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Typography variant="h6">Reporting currency</Typography>
                  <TextField
                    select
                    value={formData["currency_id"]}
                    onChange={handleChange("currency_id")}
                    disabled={formData["lock"]}
                  >
                    {currencies.map((ele) => {
                      return <MenuItem value={ele.id}>{ele.name}</MenuItem>;
                    })}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ m: 1, width: "90%" }}>
                  <Typography variant="h6">Auditor</Typography>
                  <Autocomplete
                    id="auditor"
                    value={formData["auditor"]}
                    onChange={(event, newValue) => {
                      let changeFn = handleAutoComplete("auditor");
                      if (typeof newValue === "string") {
                        changeFn(newValue);
                      }
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    inputValue={formData["auditor"]}
                    onInputChange={(event, value) =>
                      handleAutoComplete("auditor")(value)
                    }
                    renderInput={(params) => <TextField {...params} />}
                    options={auditorOptions}
                    disabled={formData["lock"]}
                  />
                </FormControl>
              </Grid>
              {/*{formData["include_zh"] && (*/}
              {/*  <Grid item xs={6}>*/}
              {/*    <FormControl sx={{ m: 1, width: "90%" }}>*/}
              {/*      <Typography variant="h6">Auditor (Chinese)</Typography>*/}
              {/*      <OutlinedInput*/}
              {/*        id="auditor"*/}
              {/*        value={formData["auditor_zh"]}*/}
              {/*        placeholder="Name of the auditor in Chinese (default name)"*/}
              {/*        onChange={handleChange("auditor_zh")}*/}
              {/*      />*/}
              {/*    </FormControl>*/}
              {/*  </Grid>*/}
              {/*)}*/}
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <Typography variant="h6">
                    First set of audited accounts?
                  </Typography>
                  <RadioGroup
                    row
                    name="is_first_audit"
                    value={formData["is_first_audit"]}
                    onChange={handleChange("is_first_audit", true)}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                      disabled={formData["lock"]}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                      disabled={formData["lock"]}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {!formData["is_first_audit"] && (
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <FormControl fullWidth sx={{ m: 1 }}>
                        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                          PREVIOUS reporting period
                        </Typography>
                        <Box sx={{ display: "inline-flex" }}>
                          <DesktopDatePicker
                            label="From date"
                            inputFormat="MM/dd/yyyy"
                            value={formData["prev_from_date"]}
                            maxDate={formData["prev_to_date"] ?? undefined}
                            onChange={handleDateOnChange("prev_from_date")}
                            onAccept={handleDateOnAccept("prev_from_date")}
                            renderInput={(params) => <TextField {...params} />}
                            disabled={formData["lock"]}
                          />
                          <Typography
                            variant="body1"
                            sx={{ margin: " 15px 30px 0 30px" }}
                          >
                            To
                          </Typography>
                          <DesktopDatePicker
                            label="To date"
                            inputFormat="MM/dd/yyyy"
                            value={formData["prev_to_date"]}
                            minDate={formData["prev_from_date"] ?? undefined}
                            maxDate={formData["curr_from_date"] ?? undefined}
                            onChange={handleDateOnChange("prev_to_date")}
                            onAccept={handleDateOnAccept("prev_to_date")}
                            renderInput={(params) => <TextField {...params} />}
                            disabled={formData["lock"]}
                          />
                        </Box>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1, width: "90%" }}>
                      <Typography variant="h6">
                        Name of previous auditor (optional)
                      </Typography>
                      <OutlinedInput
                        id="prev-auditor"
                        value={formData["prev_auditor"]}
                        placeholder="Enter name if you want previous auditor to be mentioned in report"
                        onChange={handleChange("prev_auditor")}
                        disabled={formData["lock"]}
                      />
                    </FormControl>
                  </Grid>
                  {/*{formData["include_zh"] && (*/}
                  {/*  <Grid item xs={6}>*/}
                  {/*    <FormControl sx={{ m: 1, width: "90%" }}>*/}
                  {/*      <Typography variant="h6">Name of previous auditor in Chinese (optional)</Typography>*/}
                  {/*      <OutlinedInput*/}
                  {/*        id="prev-auditor"*/}
                  {/*        value={formData["prev_auditor_zh"]}*/}
                  {/*        placeholder="Enter name if you want previous auditor to be mentioned in report"*/}
                  {/*        onChange={handleChange("prev_auditor_zh")}*/}
                  {/*      />*/}
                  {/*    </FormControl>*/}
                  {/*  </Grid>*/}
                  {/*)}*/}
                </Grid>
              )}
            </Grid>
          </Box>
        </CardContent>
        <CardActions>
          <Tooltip title={"Back"}>
            <Button disabled={true}>
              <NavigateBeforeIcon />
            </Button>
          </Tooltip>
          <Tooltip title={"Next"}>
            <LoadingButton
              loading={loading}
              disabled={!validate()}
              onClick={handleSubmit}
            >
              <NavigateNextIcon />
            </LoadingButton>
          </Tooltip>
          {project.wp_template_type === "USER_DEFINED" &&
            <Tooltip title={"Skip to step 3"}>
              <LoadingButton
                loading={loading}
                disabled={!validate()}
                onClick={handleSkip}
              >
                <SkipNextIcon />
              </LoadingButton>
            </Tooltip>}
        </CardActions>
      </Card>
    </LocalizationProvider>
  );
};

SubmitProjectForm.propTypes = {
  project: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  onFetchProjectDetail: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default SubmitProjectForm;
