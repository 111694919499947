import React from 'react';
import PropTypes from "prop-types";

const LoadingMask = props =>
  props.show ? (
    <div className="loading__container">
      <div className="loading__ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  ) : null;

LoadingMask.propTypes = {
  show: PropTypes.bool,
}

export default LoadingMask;