import { useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const UserInfo = (props) => {
  const navigate = useNavigate();

  const name = useSelector((state) => state.user.name);
  const company = useSelector((state) => state.user.company);
  const admin = useSelector((state) => state.user.isAdmin);

  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleLogout = () => {
    props.onLogout();
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleToAdminPage = () => {
    navigate("/admin");
    handleMenuClose();
  };

  const menuId = "account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleToAdminPage}>Admin Page</MenuItem>
    </Menu>
  );

  const envTag = () => {
    const env = process.env.REACT_APP_STAGE;

    if (env === "production") {
      return;
    }

    let tag;
    switch (env) {
      case "sit":
        tag = "SIT";
        break;
      default:
        tag = "DEV";
    }

    return (
      <Box
        component="div"
        sx={{
          display: "inline",
          p: 1,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "700",
        }}
      >
        {tag}
      </Box>
    );
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        background: "white",
        boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.15);",
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
            }}
          >
            AU
            <span style={{ color: "green" }}>D</span>
            TO
          </Typography>
          {envTag()}
          <Box sx={{ display: "flex", right: 0, position: "absolute" }}>
            <Tooltip title={company}>
              <Typography
                variant="subtitle1"
                noWrap
                align="center"
                sx={{
                  color: "black",
                  margin: "auto",
                  marginRight: "10px",
                }}
              >
                {name}
              </Typography>
            </Tooltip>
            {admin && (
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                sx={{ margin: "auto", height: "40px", width: "40px" }}
                onClick={handleProfileMenuOpen}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            <Tooltip title="Logout">
              <IconButton
                onClick={handleLogout}
                sx={{ margin: "auto", height: "40px", width: "40px" }}
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
      {renderMenu}
    </AppBar>
  );
};

UserInfo.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default UserInfo;