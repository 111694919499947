import { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DownloadIcon from "@mui/icons-material/Download";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import * as api from "api";
import { adminDownloadMonthlyUsage } from "api";

const getEndOfMonth = (date) => {
  let newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return newDate;
};

const getStartOfMonth = (date) => {
  let newDate = new Date(date.getFullYear(), date.getMonth(), 1);
  return newDate;
};

const getDiffMonth = (date, monDiff) => {
  let newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + monDiff);
  return newDate;
};

const toDateString = (date) => {
  if (date instanceof Date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  return null;
};

const onKeyDown = (e) => {
  e.preventDefault();
};

const DateRangePicker = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} {...props}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <Box sx={{ display: "inline-flex" }}>
            <DatePicker
              label="From date"
              views={["year", "month"]}
              value={props.startDate}
              minDate={getStartOfMonth(getDiffMonth(props.endDate, -24))}
              maxDate={props.endDate}
              onChange={props.setStartDate}
              renderInput={(params) => (
                <TextField onKeyDown={onKeyDown} size={"small"} {...params} />
              )}
            />
            <Typography variant="body1" sx={{ margin: "10px 20px 0 20px" }}>
              To
            </Typography>
            <DatePicker
              label="To date"
              views={["year", "month"]}
              value={props.endDate}
              minDate={props.startDate}
              maxDate={getEndOfMonth(getDiffMonth(props.startDate, 24))}
              onChange={props.setEndDate}
              renderInput={(params) => (
                <TextField onKeyDown={onKeyDown} size={"small"} {...params} />
              )}
            />
          </Box>
        </FormControl>
      </Box>
    </LocalizationProvider>
  );
};

const UserUsage = (props) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnMetas, setColumnMetas] = useState([]);

  const [startDate, setStartDate] = useState(
    getStartOfMonth(getDiffMonth(new Date(), -2))
  );
  const [endDate, setEndDate] = useState(getEndOfMonth(new Date()));

  const handleSetStartDate = (date) => {
    setStartDate(getStartOfMonth(date));
  };

  const handleSetEndDate = (date) => {
    setEndDate(getEndOfMonth(date));
  };

  const handleDownloadMonthlyUsage = () => {
    let sDate = toDateString(startDate);
    let eDate = toDateString(endDate);

    adminDownloadMonthlyUsage(sDate, eDate);
  };

  const createColumn = (column) => {
    return {
      accessorKey: column,
      header: column.charAt(0).toUpperCase() + column.slice(1).toLowerCase(),
      enableColumnFilter: false,
      enableSorting: false,
    };
  };

  let columns = useMemo(() => {
    let fixedColumns = [
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "company",
        header: "Company",
      },
    ];
    let dateColumns = columnMetas.map((ele) => createColumn(ele));

    return [...fixedColumns, ...dateColumns];
  }, [columnMetas]);

  const { data, isError, isFetching, isLoading, refetch } = useQuery(
    [
      "usage_statistics",
      columnFilters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    async () => {
      let sDate = toDateString(startDate);
      let eDate = toDateString(endDate);

      const res = await api.adminGetUsageStatisticsApi(
        pagination,
        columnFilters,
        sorting,
        sDate,
        eDate
      );

      setColumnMetas(res.data?.columnNames ?? []);

      return res.data;
    },
    { keepPreviousData: true }
  );

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  return (
    <MaterialReactTable
      state={{
        columnFilters,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isFetching,
        sorting,
      }}
      columns={columns}
      data={data?.data ?? []}
      rowCount={data?.totalRowCount ?? 0}
      manualFiltering
      manualPagination
      manualSorting
      onColumnFiltersChange={setColumnFilters}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      enableGlobalFilter={false}
      renderTopToolbarCustomActions={() => (
        <Box sx={{ display: "flex" }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={handleSetStartDate}
            setEndDate={handleSetEndDate}
            sx={{ float: "left" }}
          />
          <Tooltip title={"Download monthly usage"}>
            <IconButton
              onClick={handleDownloadMonthlyUsage}
              sx={{ margin: "auto" }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    />
  );
};

const queryClient = new QueryClient();

const QueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <UserUsage />
  </QueryClientProvider>
);

export default QueryProvider;
