import * as actionTypes from "store/actions/actionTypes";

const initialState = {
  isSuccess: false,
  isAuthenticated: false,
  username: null,
}

const loginSuccess = (state, action) => {
  return {
    ...state,
    isSuccess: true,
    isAuthenticated: true,
    username: action.username,
  }
}

const logout = (state) => {
  return {
    ...state,
    isAuthenticated: false,
    username: null
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action)
    case actionTypes.LOGOUT:
      return logout(state)
    default:
      return state
  }
}

export default reducer;