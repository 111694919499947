import { Box, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import LoadingMask from "component/UI/LoadingMask";
import ProjectTable from "component/Project/ProjectTable/ProjectTable";

const ProjectView = (props) =>  {
  const isLoading = useSelector((state) => state.auditor.isLoading);

  return (
    <Box>
      <ProjectTable />
      <LoadingMask show={isLoading} />
    </Box>
  );
}

export default ProjectView;
