import { downloadResponseFile, urljoin } from "../share/utils";
import axios from "../share/axios";
import { toast } from "react-toastify";

export const adminUpdateTableItemConfigApi = (excelFile) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    "/admin/table-item-configs"
  );

  let formData = new FormData();
  formData.append("excel_file", excelFile);

  return axios.post(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminCreateCompanyTemplateApi = (
  templateName,
  masterFile,
  specialConfigFile,
  enTemplateFile,
  tableTemplateFile,
  qReverseTemplateFile,
  qReverseTextFile,
  customizedWPTemplateFile
) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    "/admin/company-template"
  );

  let formData = new FormData();
  formData.append("template_name", templateName);
  formData.append("master_file", masterFile);
  formData.append("special_config_file", specialConfigFile);
  formData.append("en_template_file", enTemplateFile);
  formData.append("table_template_file", tableTemplateFile);
  formData.append("q_reverse_template_file", qReverseTemplateFile);
  formData.append("q_reverse_text_file", qReverseTextFile);
  formData.append("customized_wp_template_file", customizedWPTemplateFile);

  return axios.post(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminUpdateCompanyTemplateNameApi = (
  companyTemplateId,
  templateName
) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/admin/company-template/${companyTemplateId}/template-name`
  );

  let formData = new FormData();
  formData.append("template_name", templateName);

  return axios.put(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminUpdateCompanyTemplateApi = (
  companyTemplateId,
  templateName,
  masterFile,
  specialConfigFile,
  enTemplateFile,
  tableTemplateFile,
  qReverseTemplateFile,
  qReverseTextFile,
  customizedWPTemplateFile
) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/admin/company-template/${companyTemplateId}`
  );

  let formData = new FormData();
  formData.append("template_name", templateName);
  formData.append("master_file", masterFile);
  formData.append("special_config_file", specialConfigFile);
  formData.append("en_template_file", enTemplateFile);
  formData.append("table_template_file", tableTemplateFile);
  formData.append("q_reverse_template_file", qReverseTemplateFile);
  formData.append("q_reverse_text_file", qReverseTextFile);
  formData.append("customized_wp_template_file", customizedWPTemplateFile)

  return axios.put(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminDeleteCompanyTemplateApi = (companyTemplateId) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/admin/company-template/${companyTemplateId}`
  );

  return axios.delete(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminCreateCompanyApi = (
  companyName,
  companyTemplateIds,
  cpaList
) => {
  let url = urljoin(process.env.REACT_APP_AUDITOR_SERVICE, "/admin/company");

  let formData = new FormData();
  formData.append("company_name", companyName);
  companyTemplateIds.forEach((companyTemplateId) =>
    formData.append("company_template_ids", companyTemplateId)
  );
  cpaList.forEach((cpa, index) =>
    formData.append(`cpa_list-${index + 1}`, cpa)
  );

  return axios.post(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminUpdateCompanyApi = (
  companyId,
  companyName,
  companyTemplateIds,
  cpaList
) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/admin/company/${companyId}`
  );

  let formData = new FormData();
  formData.append("company_name", companyName);
  companyTemplateIds.forEach((companyTemplateId) =>
    formData.append("company_template_ids", companyTemplateId)
  );
  cpaList.forEach((cpa, index) =>
    formData.append(`cpa_list-${index + 1}`, cpa)
  );

  return axios.put(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminCreateUserApi = (name, email, companyId, accountType) => {
  let url = urljoin(process.env.REACT_APP_AUDITOR_SERVICE, "/admin/user");

  let formData = new FormData();
  formData.append("name", name);
  formData.append("email", email);
  formData.append("company_id", companyId);
  formData.append("account_type", accountType);

  return axios.post(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminUpdateUserApi = (userId, name, email, accountType) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/admin/user/${userId}`
  );

  let formData = new FormData();
  formData.append("name", name);
  formData.append("email", email);
  formData.append("account_type", accountType);

  return axios.put(url, formData).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminGetCompanyTemplatesApi = (
  pagination,
  columnFilters,
  sorting
) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    "/admin/company-templates"
  );

  const data = {
    start: pagination && pagination.pageIndex * pagination.pageSize,
    size: pagination && pagination.pageSize,
    filters: JSON.stringify(columnFilters ?? []),
    sorting: JSON.stringify(sorting ?? []),
  };

  const searchParams = new URLSearchParams(data);

  if (searchParams.toString()) {
    url = `${url}?${searchParams.toString()}`;
  }

  return axios.get(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminGetUserDetailsApi = (
  pagination,
  user_filter,
  columnFilters,
  sorting,
  status
) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    "/admin/user-details"
  );

  const data = {
    start: pagination && pagination.pageIndex * pagination.pageSize,
    size: pagination && pagination.pageSize,
    user_filter: user_filter ?? "",
    filters: JSON.stringify(columnFilters ?? []),
    sorting: JSON.stringify(sorting ?? []),
  };

  if (status) {
    data["status"] = status;

    const searchParams = new URLSearchParams(data);

    if (searchParams.toString()) {
      url = `${url}?${searchParams.toString()}`;
    }
  }

  return axios.get(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });
  });
};

export const adminGetUsageStatisticsApi = (
  pagination,
  columnFilters,
  sorting,
  start_date,
  end_date
) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    "/admin/usage-statistics"
  );

  const data = {
    start: pagination && pagination.pageIndex * pagination.pageSize,
    size: pagination && pagination.pageSize,
    filters: JSON.stringify(columnFilters ?? []),
    sorting: JSON.stringify(sorting ?? []),
  };

  if (start_date) {
    data["start_date"] = start_date;
  }

  if (end_date) {
    data["end_date"] = end_date;
  }

  const searchParams = new URLSearchParams(data);

  if (searchParams.toString()) {
    url = `${url}?${searchParams.toString()}`;
  }

  return axios.get(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });
  });
};

export const adminDeleteUserApi = (userId) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/admin/user/${userId}`
  );

  return axios.delete(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminDeleteCompanyApi = (companyId) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    `/admin/company/${companyId}`
  );

  return axios.delete(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });

    throw error;
  });
};

export const adminDownloadMonthlyUsage = (start_date, end_date) => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    "/admin/monthly_usage"
  );

  let data = {};
  if (start_date) {
    data["start_date"] = start_date;
  }

  if (end_date) {
    data["end_date"] = end_date;
  }

  const searchParams = new URLSearchParams(data);

  if (searchParams.toString()) {
    url = `${url}?${searchParams.toString()}`;
  }

  return axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      downloadResponseFile(res);
    })
    .catch(async (error) => {
      let message;
      if (error.code === "ECONNABORTED") {
        message = "Timeout";
      } else if (
        error.response !== undefined &&
        error.response.data !== undefined
      ) {
        message = await error.response.data.text();

        try {
          let jsonData = JSON.parse(message);
          message = jsonData.message;
        } catch (e) {}
      }
      toast.error(message, {
        autoClose: 5000,
      });

      throw error;
    });
};

export const adminGetCompanySettingApi = () => {
  let url = urljoin(
    process.env.REACT_APP_AUDITOR_SERVICE,
    "/admin/company-setting"
  );

  return axios.get(url).catch((error) => {
    let message = error.response.data.message;
    toast.error(message, {
      autoClose: 5000,
    });
  });
};
