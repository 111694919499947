import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";

const EditUserModal = (props) => {
  const { user, company, open, accountTypes, isCreate, onClose, onSubmit } =
    props;

  const [form, setForm] = useState({
    userId: null,
    companyId: null,
    email: "",
    name: "",
    accountType: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop, isBool) => (e) => {
    let value;
    if (isBool) {
      value = e.target.value === "true";
    } else {
      value = e.target.value;
    }

    setForm((prevFormData) => {
      prevFormData[prop] = value;
      return { ...prevFormData };
    });
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await onSubmit(form, isCreate);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const validate = () => {
    return (
      !isLoading &&
      form.companyId &&
      form.name &&
      form.email &&
      form.accountType
    );
  };

  useEffect(() => {
    if (user != null) {
      let form = {
        userId: user.user_id,
        email: user.email,
        name: user.name,
        isAdmin: user.is_admin,
        accountType: user.account_type,
      };

      setForm((prevForm) => {
        return {
          ...prevForm,
          ...form,
        };
      });
    }
  }, [user]);

  useEffect(() => {
    if (company != null) {
      setForm((prevForm) => {
        return {
          ...prevForm,
          companyId: company.company_id,
        };
      });
    }
  }, [company]);

  useEffect(() => {
    if (!open) {
      setForm({
        userId: null,
        companyId: null,
        email: "",
        name: "",
        accountType: null,
        isAdmin: false,
      });
    }
    setIsLoading(false);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>User Details</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <TextField
                label="User Name"
                id="user-name"
                value={form.name}
                placeholder="Enter name of the user"
                onChange={handleChange("name")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <TextField
                label="Email"
                id="user-email"
                value={form.email}
                placeholder="Enter email of the user"
                onChange={handleChange("email")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <InputLabel id="account-type">Account Type</InputLabel>
              <Select
                label="account-type"
                id="account-type"
                value={form.accountType}
                onChange={handleChange("accountType")}
              >
                {accountTypes.map((accountType) => {
                  return (
                    <MenuItem value={accountType.id}>
                      {accountType.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={handleConfirm}
          disabled={!validate()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isCreate: PropTypes.bool.isRequired,
  user: PropTypes.object,
  company: PropTypes.object.isRequired,
  accountTypes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EditUserModal;
