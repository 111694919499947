import PropTypes from "prop-types";
import _ from "lodash";
import { useEffect, useState } from "react";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

const inputValue = (e) => e.target.value;

const isEscapeKeyEvent = (event) => {
  return event.key === "Escape";
};

const TableCellEditField = (props) => {
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(props.value);

  const handleEditCancel = () => {
    setText(props.value);
    setIsEditing(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await props.onSubmit(text);
      setIsEditing(false);
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    setText(props.value);
  }, [props.value]);

  return isEditing ? (
    <TextField
      className="bg-transparent border-2 border-black border-solid"
      fullWidth
      autoFocus
      margin={"dense"}
      variant={"standard"}
      value={text}
      onChange={_.flow(inputValue, setText)}
      onKeyDown={(event) => {
        if (isEscapeKeyEvent(event)) {
          event.preventDefault();
          event.stopPropagation();
          handleEditCancel();
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <LoadingButton loading={loading} edge="end" onClick={handleSubmit}>
              <SendIcon />
            </LoadingButton>
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <Tooltip title={"Double click to edit"}>
      <div className="select-none" onDoubleClick={() => setIsEditing(true)}>
        {text}
      </div>
    </Tooltip>
  );
};

TableCellEditField.propTypes = {
  value: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TableCellEditField;
