import PropTypes from "prop-types";
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import * as api from "api";
import { ProjectFileType, ProjectStatus } from "share/constants";
import ArticleIcon from "@mui/icons-material/Article";
import ErrorIcon from "@mui/icons-material/Error";
import { toReadable } from "share/utils";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useEffect, useState } from "react";
import ConfirmDialog from "../../../UI/ConfirmDialog";

const downloadAreaStyle = {
  padding: "50px",
  background: "#F1F0EF",
  borderRadius: "10px",
  display: "inline-block",
};

const subTitleStyle = {
  marginTop: "15px",
};

const ExtractExcelPanel = (props) => {
  const {
    project,
    projectId,
    onBack,
    onNext,
    initLoading,
    onFetchProjectDetail,
  } = props;

  const [init, setInit] = useState(initLoading);
  const [intervalId, setIntervalId] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const enableRegenerate = () => {
    if (project?.["extract_table_status"].status === ProjectStatus.COMPLETE) {
      return false;
    }

    let REGEN_MIN = 1 * 60 * 1000;

    let statusDate = new Date(project?.["extract_table_status"]["update_date"]);
    let date = new Date();

    return date - statusDate > REGEN_MIN;
  };

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleNext = () => {
    onNext();
  };

  const handleBack = () => {
    onBack();
  };

  const handleDownloadReport = async () => {
    await api.downloadProjectFileApi(
      projectId,
      ProjectFileType.GENERATED_WP_FILE
    );
  };

  const projectState = () => {
    if (init) {
      return ProjectStatus.PROCESSING;
    }

    return project?.["extract_table_status"].status;
  };

  const handleRegenerateIntermediate = async () => {
    await api.extractExcelApi(projectId, true);
    handleConfirmDialogClose();
  };

  useEffect(() => {
    if (initLoading) {
      setTimeout(() => setInit(false), 2000);
    }
  }, [initLoading]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      onFetchProjectDetail();
    }, 3000);

    setIntervalId(intervalId);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let extractStatus = project?.["extract_table_status"]?.status;
    if (
      extractStatus === ProjectStatus.COMPLETE ||
      extractStatus === ProjectStatus.FAIL
    ) {
      clearInterval(intervalId);
    }
  }, [project]);

  return (
    <div>
      <ConfirmDialog
        open={confirmDialogOpen}
        title={"Regenerate Intermediate File"}
        message={"Confirm to regenerate intermediate file?"}
        onClose={handleConfirmDialogClose}
        onConfirm={handleRegenerateIntermediate}
      />
      <Card elevation={0}>
        <CardHeader title={"Generate Intermediate File"} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ height: "300px", display: "flex", textAlign: "center" }}
            >
              {(() => {
                switch (projectState()) {
                  case ProjectStatus.COMPLETE:
                    return (
                      <Box sx={{ margin: "auto" }}>
                        <ButtonBase
                          style={downloadAreaStyle}
                          onClick={handleDownloadReport}
                        >
                          <SvgIcon fontSize="large">
                            <ArticleIcon />
                          </SvgIcon>
                          <Typography variant={"body1"} style={subTitleStyle}>
                            Please click here to download the intermediate file
                          </Typography>
                        </ButtonBase>
                      </Box>
                    );
                  case ProjectStatus.FAIL:
                    return (
                      <Box sx={{ margin: "auto" }}>
                        <SvgIcon fontSize="large">
                          <ErrorIcon />
                        </SvgIcon>
                        <Typography variant={"body1"} style={subTitleStyle}>
                          Fail to generate intermediate file. Please retry.
                        </Typography>
                      </Box>
                    );
                  case ProjectStatus.NOT_STARTED:
                    return (
                      <Box sx={{ margin: "auto" }}>
                        <SvgIcon fontSize="large">
                          <ErrorIcon />
                        </SvgIcon>
                        <Typography variant={"body1"} style={subTitleStyle}>
                          Please upload the working paper to generate the
                          intermediate file
                        </Typography>
                      </Box>
                    );
                  default:
                    return (
                      <Box sx={{ margin: "auto" }}>
                        <CircularProgress />
                        <Typography variant={"body1"} style={subTitleStyle}>
                          {projectState()
                            ? toReadable(projectState())
                            : "Processing"}
                          ...
                        </Typography>
                      </Box>
                    );
                }
              })()}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {enableRegenerate() && (
                <Button variant={"outlined"} onClick={handleConfirmDialogOpen}>
                  Regenerate
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          <Tooltip title={"Back"}>
            <Button onClick={handleBack}>
              <NavigateBeforeIcon />
            </Button>
          </Tooltip>
          <Tooltip title={"Next"}>
            <Button onClick={handleNext}>
              <NavigateNextIcon />
            </Button>
          </Tooltip>
        </CardActions>
      </Card>
    </div>
  );
};

ExtractExcelPanel.propTypes = {
  initLoading: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  onFetchProjectDetail: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default ExtractExcelPanel;
