import MaterialReactTable from "material-react-table";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useMemo, useState } from "react";
import * as api from "api";
import { Box, IconButton, Tooltip } from "@mui/material";
import ProjectDetailPanel from "./ProjectDetailPanel";
import CreateProjectDialog from "./CreateProjectDialog";
import TableCellEditField from "component/UI/TableCellEditField";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmPopover from "component/UI/ConfirmPopover";
import { useNavigate } from "react-router-dom";

const borderRadiusStyle = {
  borderRadius: "10px",
};

const ProjectTable = (props) => {
  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [createProjectDialogOpen, setCreateProjectDialogOpen] = useState(false);
  const [deleteProjectAnchorEl, setDeleteProjectAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [projectTemplates, setProjectTemplates] = useState([]);

  const columns = useMemo(
    () => [
      {
        header: "Project Name",
        accessorKey: "project_name",
        Cell: ({ row, cell }) => (
          <TableCellEditField
            onSubmit={(projectName) =>
              handleUpdateProjectName(row.original["project_id"], projectName)
            }
            value={cell.getValue()}
          />
        ),
      },
      {
        header: "Create Date",
        accessorKey: "create_date",
        enableColumnFilter: false,
      },
    ],
    []
  );

  const { data, isError, isFetching, isLoading, refetch } = useQuery(
    [
      "projects",
      columnFilters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    async () => {
      const response = await api.getProjectsApi(
        pagination,
        columnFilters,
        sorting
      );
      return response.data;
    },
    { keepPreviousData: true }
  );

  const handleCreateProjectDialogOpen = (e) => {
    setCreateProjectDialogOpen(true);
  };

  const handleCreateProjectPopoverClose = () => {
    setCreateProjectDialogOpen(false);
  };

  const handleDeleteProjectPopoverOpen = (e, row) => {
    setSelectedRow(row);
    setDeleteProjectAnchorEl(e.currentTarget);
  };

  const handleDeleteProjectPopoverClose = () => {
    clearSelectedRow();
    setDeleteProjectAnchorEl(null);
  };

  const clearSelectedRow = () => {
    setSelectedRow(null);
  };

  const handleCreateNewProject = async (projectName, templateId, workingPaperType) => {
    let res = await api.createProjectApi(projectName, templateId, workingPaperType);
    let projectId = res.data.project_id;

    navigate(`/project/${projectId}`);
  };

  const handleUpdateProjectName = async (projectId, projectName) => {
    await api.updateProjectApi(projectId, projectName);
    await refetch();
  };

  const handleDeleteProject = async () => {
    let projectId = selectedRow?.["project_id"];
    if (projectId) {
      try {
        await api.deleteProjectApi(projectId);
      } catch (error) {}
    }

    handleDeleteProjectPopoverClose();
    await refetch();
  };

  const getProjectTemplates = async () => {
    let res = await api.getProjectTemplatesApi();
    setProjectTemplates(res.data);
  };

  useEffect(() => {
    getProjectTemplates();
  }, []);

  return (
    <Box>
      <ConfirmPopover
        anchorEl={deleteProjectAnchorEl}
        message={"Are you sure you want to delete this project?"}
        onClose={handleDeleteProjectPopoverClose}
        onConfirm={handleDeleteProject}
        title={selectedRow && `Delete project ${selectedRow["project_name"]}`}
      />
      <CreateProjectDialog
        templates={projectTemplates}
        open={createProjectDialogOpen}
        onSubmit={handleCreateNewProject}
        onClose={handleCreateProjectPopoverClose}
      />
      <MaterialReactTable
        columns={columns}
        data={data?.data ?? []}
        rowCount={data?.totalRowCount ?? 0}
        state={{
          columnFilters,
          pagination,
          showAlertBanner: isError,
          sorting,
        }}
        enableGlobalFilter={false}
        manualFiltering
        manualPagination
        manualSorting
        onColumnFiltersChange={setColumnFilters}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => [
          <Tooltip title="Fill-in Project">
            <IconButton
              onClick={() => navigate(`/project/${row.original["project_id"]}`)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Delete Project">
            <IconButton
              onClick={(e) => handleDeleteProjectPopoverOpen(e, row.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>,
        ]}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ display: "flex" }}>
            <Tooltip arrow title="Refresh Data">
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Create new project">
              <IconButton onClick={handleCreateProjectDialogOpen}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderDetailPanel={({ row }) => (
          <ProjectDetailPanel
            project_owner={row.original["project_owner"]}
            createDate={row.original["create_date"]}
            projectId={row.original["project_id"]}
            template={row.original["template"]}
            extractTableStatus={row.original["extract_table_status"]}
            generateDocumentStatus={row.original["generate_document_status"]}
            generatedWpFile={row.original["generated_wp_file"]}
            generatedDocument={row.original["generated_report_file"]}
          />
        )}
        muiTablePaperProps={{ sx: borderRadiusStyle, elevation: 3 }}
        muiBottomToolbarProps={{ sx: borderRadiusStyle }}
        muiTopToolbarProps={{ sx: borderRadiusStyle }}
      />
    </Box>
  );
};

const queryClient = new QueryClient();

const QueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <ProjectTable />
  </QueryClientProvider>
);

export default QueryProvider;
