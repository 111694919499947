import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import * as api from "api";
import UploadArea from "component/UI/UploadArea/UploadArea";
import ArticleIcon from "@mui/icons-material/Article";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import LoadingButton from "@mui/lab/LoadingButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";

const DisplayDocumentCard = (props) => {
  const { project } = props;

  const isFirstAudit = project?.["is_first_audit"];
  const user_prev_file = project?.["user_upload_prev_file"];
  const user_wp_file = project?.["user_upload_wp_file"];

  const isValid = isFirstAudit
    ? user_wp_file != null
    : user_prev_file != null && user_wp_file != null;

  const documentNotUploadView = () => (
    <Box sx={{ margin: "auto" }}>
      <SvgIcon fontSize="large">
        <AssignmentLateIcon />
      </SvgIcon>
      <Typography variant={"body1"}>Document not uploaded.</Typography>
    </Box>
  );

  const displayDocumentView = () =>
    isFirstAudit ? (
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ margin: "auto" }}>
            <Typography variant={"h5"} sx={{ marginBottom: "30px" }}>
              WP File
            </Typography>
            <SvgIcon fontSize="large">
              <ArticleIcon />
            </SvgIcon>
            <Typography variant={"body1"}>
              {user_wp_file["file_name"]}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    ) : (
      <Grid container>
        <Grid item xs={6}>
          <Box sx={{ margin: "auto" }}>
            <Typography variant={"h5"} sx={{ marginBottom: "30px" }}>
              Previous AFS
            </Typography>
            <SvgIcon fontSize="large">
              <ArticleIcon />
            </SvgIcon>
            <Typography variant={"body1"}>
              {user_prev_file["file_name"]}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ margin: "auto" }}>
            <Typography variant={"h5"} sx={{ marginBottom: "30px" }}>
              WP File
            </Typography>
            <SvgIcon fontSize="large">
              <ArticleIcon />
            </SvgIcon>
            <Typography variant={"body1"}>
              {user_wp_file["file_name"]}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );

  return (
    <Card elevation={0}>
      <CardHeader title={"Input Files"} />
      <CardContent
        sx={{
          height: "250px",
          display: "flex",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        {isValid ? displayDocumentView() : documentNotUploadView()}
      </CardContent>
      <CardActions disableSpacing>
        <Tooltip title={"Back"}>
          <Button onClick={props.onBack}>
            <NavigateBeforeIcon />
          </Button>
        </Tooltip>
        <Tooltip title={"Next"}>
          <Button disabled={!isValid} onClick={props.onNext}>
            <NavigateNextIcon />
          </Button>
        </Tooltip>
        <Tooltip title={"Re-upload document"}>
          <Button onClick={props.onFileUploadAreaOpen}>
            <EditIcon />
          </Button>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

const UploadDocumentCard = (props) => {
  const { isFirstAudit } = props;
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await props.onSubmit();
    } catch (err) {}

    setLoading(false);
  };

  const validate = () => {
    return isFirstAudit
      ? props.uploadedFiles["wpFile"].length > 0
      : props.uploadedFiles["wpFile"].length > 0 &&
          props.uploadedFiles["prevDoc"].length > 0;
  };

  const wpFileOnlyUpload = () => (
    <Grid container item>
      <Grid item xs={12}>
        <UploadArea
          files={props.uploadedFiles["wpFile"]}
          onUpdateFiles={props.onFileUpload("wpFile")}
          allowMultiple={false}
          maxFiles={1}
          name={"please upload WP File"}
          acceptedFileTypes={[".xlsx"]}
        />
      </Grid>
    </Grid>
  );

  const fullFileUpload = () => (
    <Grid container item>
      <Grid item xs={12} md={6}>
        <UploadArea
          files={props.uploadedFiles["prevDoc"]}
          onUpdateFiles={props.onFileUpload("prevDoc")}
          allowMultiple={false}
          maxFiles={1}
          name={"Please upload last year’s Audited FS"}
          subTitle={
            "(including the text of Directors' Report and Independent Auditors' Report)"
          }
          acceptedFileTypes={[".docx", ".xlsx"]}
          fileSize={10485760}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <UploadArea
          files={props.uploadedFiles["wpFile"]}
          onUpdateFiles={props.onFileUpload("wpFile")}
          allowMultiple={false}
          maxFiles={1}
          name={"Please upload this year's Working Papers or Trial Balance"}
          subTitle={"(including this year and last year's numbers)"}
          acceptedFileTypes={[".xlsx"]}
          fileSize={10485760}
        />
      </Grid>
    </Grid>
  );

  return (
    <Card elevation={0}>
      <CardHeader
        title={"Upload Input Files"}
        action={
          <Button onClick={props.onFileUploadAreaClose}>
            <CloseIcon />
          </Button>
        }
      />
      <CardContent
        sx={{ minHeight: "300px", display: "flex", textAlign: "center" }}
      >
        <Grid container>
          <Grid item xs={12}>
            {isFirstAudit ? wpFileOnlyUpload() : fullFileUpload()}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ margin: "auto", width: "100%" }}>
              <Tooltip title={"Upload document"}>
                <LoadingButton
                  disabled={!validate()}
                  loading={loading}
                  onClick={handleSubmit}
                  size={"large"}
                >
                  <CloudUploadIcon fontSize={"large"} />
                </LoadingButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const PreviousDocumentFileForm = (props) => {
  const { projectId, project, onFetchProjectDetail, onNext, onBack } = props;

  const [dropFileAreaOpen, setDropFileAreaOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({
    prevDoc: [],
    wpFile: [],
  });

  let isFirstAudit = project?.["is_first_audit"];
  let user_prev_file = project?.["user_upload_prev_file"];
  let user_wp_file = project?.["user_upload_wp_file"];

  let isFileValid = isFirstAudit
    ? user_wp_file != null
    : user_prev_file != null && user_wp_file != null;

  const handleFileUpload = (type) => (files) => {
    uploadedFiles[type] = files;

    setUploadedFiles((prevState) => ({
      ...prevState,
      ...uploadedFiles,
    }));
  };

  const handleFileSubmit = async () => {
    let prevDoc =
      uploadedFiles["prevDoc"].length > 0
        ? uploadedFiles["prevDoc"][0].file
        : null;
    let wpFile =
      uploadedFiles["wpFile"].length > 0
        ? uploadedFiles["wpFile"][0].file
        : null;

    if (wpFile) {
      await api.uploadPrevDocApi(projectId, prevDoc, wpFile);
      await onFetchProjectDetail();
      handleFileUploadAreaClose();
    }
  };

  const handleFileUploadAreaOpen = () => {
    setDropFileAreaOpen(true);
  };

  const handleFileUploadAreaClose = () => {
    setDropFileAreaOpen(false);
  };

  const handleBack = () => {
    onBack();
  };

  const handleNext = () => {
    onNext();
  };

  useEffect(() => {
    if (!isFileValid) {
      handleFileUploadAreaOpen();
    }
  }, [project]);

  return dropFileAreaOpen ? (
    <UploadDocumentCard
      isFirstAudit={isFirstAudit}
      uploadedFiles={uploadedFiles}
      onFileUpload={handleFileUpload}
      onFileUploadAreaClose={handleFileUploadAreaClose}
      onSubmit={handleFileSubmit}
    />
  ) : (
    <DisplayDocumentCard
      project={project}
      onBack={handleBack}
      onFileUploadAreaOpen={handleFileUploadAreaOpen}
      onNext={handleNext}
    />
  );
};

PreviousDocumentFileForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  onFetchProjectDetail: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default PreviousDocumentFileForm;
