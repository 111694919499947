import * as api from "api";
import * as constants from "share/constants";
import { useEffect, useState } from "react";
import MaterialReactTable from "material-react-table";
import UserSubTable from "./UserSubTable";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import EditCompanyModal from "./EditCompanyModal";
import EditUserModal from "./EditUserModal";
import ConfirmDialog from "../UI/ConfirmDialog";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

const CompanyView = (props) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [editCompanyModalOpen, setEditCompanyModalOpen] = useState(false);
  const [isCreateCompany, setIsCreateCompany] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [isCreateUser, setIsCreateUser] = useState(false);

  const [deleteCompanyDialogOpen, setDeleteCompanyDialogOpen] = useState(false);
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [filterStatus, setFilterStatus] = useState(
    constants.UserFilterStatus.ACTIVE
  );

  const [companySettings, setCompanySettings] = useState([]);

  const columns = [
    {
      accessorKey: "company",
      header: "Company",
    },
    {
      accessorKey: "company_templates",
      header: "Company Template",
      Cell: ({ cell }) => {
        let templateNameList = cell.getValue().map((template) => template.name);
        let str = templateNameList.join(", ");
        return <div>{str}</div>;
      },
    },
    {
      accessorFn: (row) => (row.deleted ? "Deleted" : "Active"),
      header: "Status",
      enableColumnFilter: false,
    },
  ];

  const openConfirmDeleteCompanyDialog = (company) => {
    setSelectedCompany(company);
    setDeleteCompanyDialogOpen(true);
  };

  const closeConfirmDeleteCompanyDialog = () => {
    setSelectedCompany(null);
    setDeleteCompanyDialogOpen(false);
  };

  const openConfirmDeleteUserDialog = (user) => {
    setSelectedUser(user);
    setDeleteUserDialogOpen(true);
  };

  const closeConfirmDeleteUserDialog = () => {
    setSelectedUser(null);
    setDeleteUserDialogOpen(false);
  };

  const openEditCompanyModal = (company, isCreate) => {
    setSelectedCompany(company);
    setIsCreateCompany(isCreate);
    setEditCompanyModalOpen(true);
  };

  const closeEditCompanyModal = () => {
    setSelectedCompany(null);
    setIsCreateCompany(false);
    setEditCompanyModalOpen(false);
  };

  const openEditUserModal = (user, company, isCreate) => {
    setSelectedCompany(company);
    setSelectedUser(user);
    setIsCreateUser(isCreate);
    setEditUserModalOpen(true);
  };

  const closeEditUserModal = () => {
    setSelectedCompany(null);
    setSelectedUser(null);
    setIsCreateUser(false);
    setEditUserModalOpen(false);
  };

  const handleStatusChange = (e) => {
    setFilterStatus(e.target.value);
  };

  const { data, isError, isFetching, isLoading, refetch } = useQuery(
    [
      "user_details",
      globalFilter,
      columnFilters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    async () => {
      const res = await api.adminGetUserDetailsApi(
        pagination,
        globalFilter,
        columnFilters,
        sorting,
        filterStatus
      );
      return res.data;
    },
    { keepPreviousData: true }
  );

  const handleSubmitCompany = async (form, isCreate) => {
    if (isCreate) {
      await api.adminCreateCompanyApi(
        form.companyName,
        form.companyTemplateIds,
        form.cpaList
      );
    } else {
      await api.adminUpdateCompanyApi(
        form.companyId,
        form.companyName,
        form.companyTemplateIds,
        form.cpaList
      );
    }

    refetch();
    closeEditCompanyModal();
  };

  const handleSubmitUser = async (form, isCreate) => {
    if (isCreate) {
      await api.adminCreateUserApi(form.name, form.email, form.companyId, form.accountType);
    } else {
      await api.adminUpdateUserApi(form.userId, form.name, form.email, form.accountType);
    }

    closeEditUserModal();
    refetch();
  };

  const handleDeleteCompany = async () => {
    closeConfirmDeleteCompanyDialog();

    if (selectedCompany) {
      await api.adminDeleteCompanyApi(selectedCompany.company_id);
    }

    refetch();
  };

  const handleDeleteUser = async () => {
    closeConfirmDeleteUserDialog();

    if (selectedUser) {
      await api.adminDeleteUserApi(selectedUser.user_id);
    }

    refetch();
  };

  const handleGetCompanySettings = async () => {
    let res = await api.adminGetCompanySettingApi();
    let companySettings = res.data ?? [];

    setCompanySettings(companySettings);
  };

  useEffect(() => {
    refetch();
  }, [filterStatus]);

  useEffect(() => {
    handleGetCompanySettings();
  }, []);

  return (
    <Box>
      <ConfirmDialog
        id="delete-company-dialog"
        open={deleteCompanyDialogOpen}
        title={"Delete company"}
        message={
          selectedCompany &&
          `Are you sure you want to delete ${selectedCompany.company}?`
        }
        onClose={closeConfirmDeleteCompanyDialog}
        onConfirm={handleDeleteCompany}
      />
      <ConfirmDialog
        id="delete-user-dialog"
        open={deleteUserDialogOpen}
        title={"Delete User"}
        message={
          selectedUser &&
          `Are you sure you want to delete ${selectedUser.email}?`
        }
        onClose={closeConfirmDeleteUserDialog}
        onConfirm={handleDeleteUser}
      />
      <EditUserModal
        open={editUserModalOpen}
        isCreate={isCreateUser}
        user={selectedUser}
        company={selectedCompany}
        accountTypes={companySettings?.account_types ?? []}
        onClose={closeEditUserModal}
        onSubmit={handleSubmitUser}
      />
      <EditCompanyModal
        company={selectedCompany}
        isCreate={isCreateCompany}
        onClose={closeEditCompanyModal}
        onSubmit={handleSubmitCompany}
        open={editCompanyModalOpen}
      />
      <MaterialReactTable
        columns={columns}
        data={data?.data ?? []} //data is undefined on first render
        rowCount={data?.totalRowCount ?? 0}
        state={{
          globalFilter,
          columnFilters,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
          showGlobalFilter: true,
        }}
        manualFiltering
        manualPagination
        manualSorting
        onGlobalFilterChange={setGlobalFilter}
        positionGlobalFilter="left"
        muiSearchTextFieldProps={{
          placeholder: "Search all users",
          sx: { minWidth: "300px" },
          variant: "outlined",
        }}
        onColumnFiltersChange={setColumnFilters}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderDetailPanel={({ row }) => (
          <UserSubTable
            users={row.original.users}
            company={row.original}
            onSelectUser={openEditUserModal}
            onDeleteUser={openConfirmDeleteUserDialog}
          />
        )}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            size: 150, //make actions column wider
          },
        }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => [
          <Tooltip title="Edit Company">
            <IconButton
              onClick={() => openEditCompanyModal(row.original, false)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Add User">
            <IconButton
              onClick={() => openEditUserModal(null, row.original, true)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Delete User">
            <IconButton
              onClick={() => openConfirmDeleteCompanyDialog(row.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>,
        ]}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ width: "100%" }}>
            <Tooltip title="Create company" sx={{ float: "right" }}>
              <IconButton onClick={() => openEditCompanyModal(null, true)}>
                <AddBusinessIcon />
              </IconButton>
            </Tooltip>
            <TextField
              select
              label="Status"
              size="small"
              value={filterStatus}
              onChange={handleStatusChange}
              sx={{
                float: "right",
                margin: "0 10px 0 10px",
                minWidth: "130px",
              }}
            >
              {Object.values(constants.UserFilterStatus).map((ele) => {
                return <MenuItem value={ele}>{ele}</MenuItem>;
              })}
            </TextField>
          </Box>
        )}
      />
    </Box>
  );
};

const queryClient = new QueryClient();

const QueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <CompanyView />
  </QueryClientProvider>
);

export default QueryProvider;
